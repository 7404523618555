"use client";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnFiltersState,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { useState } from "react";
import { PlusIcon } from "@/assets/Icons/icon";
import CreateAttendee from "./CreateAttendee";
import TableFooter from "../Table/TableFooter";
import { Skeleton } from "../TableSkeleton/Skeleton";
import { AlertCircle, Search, UserIcon } from "lucide-react";
import { useAtom } from "jotai";
import { userDetailsAtom } from "../../atom/atom";
import { IAttendee } from "../../types/attendeeTypes";

interface DataTableAttendeesProps<TData extends IAttendee, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  onClickUser?: () => void;
  filtersDisabled?: boolean;
  noFilters?: boolean;
  refetchData?: () => void;
  isLoading: boolean;
  userUuid?: string;
}

const SkeletonRow = ({ columnCount }: { columnCount: number }) => (
  <tr className="bg-white">
    {Array(columnCount)
      .fill(0)
      .map((_, index) => (
        <td key={index} className="px-6 py-3 text-sm border-b border-gray-200">
          <Skeleton className="h-6 w-full" />
        </td>
      ))}
  </tr>
);

export function DataTableAttendees<TData extends IAttendee, TValue>({
  columns,
  data,
  filtersDisabled,
  noFilters = false,
  refetchData,
  isLoading = false,
  userUuid,
}: DataTableAttendeesProps<TData, TValue>) {
  const [user] = useAtom(userDetailsAtom);
  const newData = data.sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return dateB.getTime() - dateA.getTime();
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [isCreateAttendeeOpen, setIsCreateAttendeeOpen] = useState(false);

  const table = useReactTable({
    data: newData,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      columnFilters,
    },
    initialState: {
      pagination: {
        pageSize: 5,
      },
    },
  });

  const isAdminSection = window?.location?.pathname.includes("admin");

  return (
    <>
      <CreateAttendee
        isOpen={isCreateAttendeeOpen}
        onClose={() => setIsCreateAttendeeOpen(false)}
        onSuccess={() => {
          setIsCreateAttendeeOpen(false);
          if (refetchData) refetchData();
        }}
        userUuid={userUuid}
      />

      {user?.roles.includes("staff") && !isAdminSection && (
        <div className="flex items-center p-4 mb-4 text-sm rounded-3xl bg-red-50 text-red-800 border border-red-200">
          <AlertCircle className="flex-shrink-0 inline w-5 h-5 mr-3" />
          <div>
            Staff accounts are currently unable to create attendees and book
            sessions. Please log-in as a booking user.
          </div>
        </div>
      )}

      {/* Filter Section */}
      {!noFilters && !filtersDisabled && (
        <div className="mb-4 flex items-center justify-between gap-2 bg-white p-4 rounded-3xl">
          <div className="flex flex-col lg:flex-row items-start lg:items-center gap-4 w-full lg:w-auto">
            <div className="relative w-full lg:w-48">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-4 w-4 text-[#687d88]" />
              </div>
              <input
                type="text"
                value={
                  (table.getColumn("name")?.getFilterValue() as string) ?? ""
                }
                onChange={(event) =>
                  table.getColumn("name")?.setFilterValue(event.target.value)
                }
                placeholder="Search by Name"
                className="w-full shadow-sm py-2 px-8 border focus:ring-primary_colour focus:border-primary_colour block pl-10 sm:text-sm border-gray-300 rounded-3xl text-black"
                disabled={filtersDisabled}
              />
            </div>
          </div>
          <button
            onClick={() => setIsCreateAttendeeOpen(true)}
            disabled={user?.roles.includes("staff")}
            className="hidden lg:inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-3xl shadow-sm text-white bg-primary_colour hover:bg-hover_primary focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:bg-blue-500 disabled:cursor-not-allowed"
          >
            <PlusIcon /> Create Attendee
          </button>
        </div>
      )}

      {/* Table Section */}
      <div className="bg-white border border-gray-200 rounded-3xl shadow-sm">
        <div className="relative rounded-t-3xl overflow-auto">
          <div className="min-w-[1024px]">
            <table className="w-full">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        style={{ minWidth: "150px" }}
                      >
                        {header.isPlaceholder ? null : (
                          <div>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {isLoading
                  ? Array(5)
                      .fill(0)
                      .map((_, index) => (
                        <SkeletonRow key={index} columnCount={columns.length} />
                      ))
                  : table.getRowModel().rows.map((row) => (
                      <tr key={row.id} className="hover:bg-gray-50">
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            className="px-6 py-3 text-sm border-b border-gray-200"
                          >
                            <div className="whitespace-nowrap">
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>

        <TableFooter table={table} />

        {!isLoading && table.getRowCount() === 0 && (
          <div className="flex flex-col items-center justify-center py-12 px-4 text-center">
            <div className="bg-gray-100 rounded-full p-3 mb-4">
              <UserIcon className="h-8 w-8 text-gray-400" />
            </div>
            <h3 className="text-lg font-medium text-gray-900 mb-2">
              No attendees yet
            </h3>
            <p className="text-sm text-gray-500 max-w-sm mb-4">
              Add attendees to manage bookings for yourself, family members, or
              others you're booking for.
            </p>
            <button
              onClick={() => setIsCreateAttendeeOpen(true)}
              disabled={user?.roles.includes("staff")}
              className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-full text-primary_colour bg-primary_colour/10 hover:bg-primary_colour/20 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary_colour disabled:cursor-not-allowed"
            >
              <PlusIcon /> Add an attendee
            </button>
          </div>
        )}
      </div>
    </>
  );
}
