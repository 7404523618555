import { useState } from "react";
import { ChevronDown, ChevronUp, User } from "lucide-react";

import { IAttendee } from "../../types/attendeeTypes";

const AttendeeDisplay = ({ attendees }: { attendees: IAttendee[] }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const displayCount = 3;

  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <span className="text-sm font-medium text-gray-700">
          Current Attendees ({attendees.length})
        </span>
        {attendees.length > displayCount && (
          <button
            onClick={toggleExpand}
            className="text-sm text-blue-600 hover:text-blue-800 flex items-center"
          >
            {isExpanded ? (
              <>
                Show Less <ChevronUp className="ml-1 h-4 w-4" />
              </>
            ) : (
              <>
                Show More <ChevronDown className="ml-1 h-4 w-4" />
              </>
            )}
          </button>
        )}
      </div>
      <div className="flex flex-wrap gap-2">
        {(isExpanded ? attendees : attendees?.slice(0, displayCount)).map(
          (attendee, index) => (
            <div
              key={index}
              className="flex items-center bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded-full"
            >
              <User className="mr-1 h-3 w-3" />
              {attendee.person.firstName} {attendee.person.lastName}
            </div>
          ),
        )}
        {!isExpanded && attendees.length > displayCount && (
          <div className="flex items-center bg-gray-100 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
            +{attendees.length - displayCount} more
          </div>
        )}
      </div>
    </div>
  );
};

export default AttendeeDisplay;
