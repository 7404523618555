import { PlusCircle } from "lucide-react";
import { useMemo, useState } from "react";
import CreateAttendee from "../../../Attendees/CreateAttendee";
import { useGetAttendees } from "../../../../core/api/user/user.queries";
import { DataTableAttendees } from "../../../Attendees/DataTableAttendee";
import { getColumnsAttendees } from "../../../Attendees/ColumnsAttendees";

interface BookingsProps {
  handleFinish: () => void;
  handlePreviousStep: () => void;
  userUuid: string;
}

const CreateAttendeeStep = ({
  handleFinish,
  handlePreviousStep,
  userUuid,
}: BookingsProps) => {
  const [isCreateAttendeeOpen, setIsCreateAttendeeOpen] = useState(false);

  const {
    isLoading: isAttendeeDataLoading,
    data: attendeeData,
    refetch: refetchAttendees,
  } = useGetAttendees(userUuid);

  const columnsAttendees = useMemo(
    () => getColumnsAttendees(undefined, userUuid),
    []
  );

  function handleModalOpen() {
    setIsCreateAttendeeOpen(true);
  }

  return (
    <>
      <CreateAttendee
        isOpen={isCreateAttendeeOpen}
        onClose={() => setIsCreateAttendeeOpen(false)}
        onSuccess={() => {
          setIsCreateAttendeeOpen(false);
          if (refetchAttendees) refetchAttendees();
        }}
        userUuid={userUuid}
      />
      <div className="bg-white py-4 px-6 rounded-2xl w-full">
        {/* Notification settings */}

        <section className="mb-5 flex items-center justify-between">
          <div>
            <h3 className="text-lg font-semibold text-[#1A1F3D]">Attendee</h3>
            <p className="text-sm text-[#1A1F3D]">
              Create attendees for the user
            </p>
          </div>

          <div className="flex gap-2 items-center">
            <div
              onClick={handleModalOpen}
              className="w-fit flex justify-center items-center gap-2 py-2 px-2 md:px-3 rounded-lg md:rounded-3xl bg-primary_colour text-white text-sm cursor-pointer hover:opacity-80 active:bg-[#1A1F3D] disabled:opacity-50"
            >
              <PlusCircle className="h-[22px] w-[22px] md:h-5 md:w-5" />
              <span className="hidden md:inline-block">Add Attendee</span>
            </div>
          </div>
        </section>

        <hr className="bg-[#E5E7F0] mb-4" />

        {/* <DataTableActivitySession
          columns={columnsActivitySessions}
          data={sessionsData ?? []}
          isLoading={!sessionDataFetched}
        /> */}

        <DataTableAttendees
          columns={columnsAttendees}
          data={attendeeData ?? []}
          refetchData={refetchAttendees}
          isLoading={isAttendeeDataLoading}
          noFilters
          userUuid={userUuid}
        />

        <div className="mb-6"></div>

        <div className="flex justify-end items-center w-full">
          <div className="flex items-center gap-3">
            <div
              onClick={handlePreviousStep}
              className="w-32 flex justify-center items-center py-2 rounded-3xl border border-primary_colour text-primary_colour text-sm font-medium cursor-pointer hover:shadow-custom-hover active:bg-[#E5E7F0] disabled:opacity-50"
            >
              Back
            </div>
            <div
              onClick={handleFinish}
              className="w-32 flex justify-center items-center py-2 rounded-3xl bg-primary_colour text-white text-sm font-medium cursor-pointer hover:opacity-80 active:bg-[#1A1F3D] disabled:opacity-50"
            >
              Finish
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAttendeeStep;
