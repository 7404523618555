import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { CreateUserSteps } from "@/constants/consts";
import { ChevronLeft } from "lucide-react";
import { CreateUserSchema } from "../../../constants/validations/users";
import UserStepper from "./UserStepper";
import UserStepperMobile from "./UserStepperMobile";
import UserDetails from "./Steps/UserDetails";
import {
  useCreateBookingUser,
  useEditBookingUserById,
} from "../../../core/api/user/user.mutation";
import { toast } from "../../ui/use-toast";
import axios from "axios";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import CreateAttendeeStep from "./Steps/CreateAttendeeStep";
import { useQueryClient } from "react-query";
import { USER_KEYS } from "../../../core/api/user/user.queries";

interface CreateUserStepFormProps {
  currentStep: CreateUserSteps;
  setCurrentStep: (step: CreateUserSteps) => void;
}

const CreateUserStepForm: React.FC<CreateUserStepFormProps> = ({
  currentStep,
  setCurrentStep,
}) => {
  const queryClient = useQueryClient();

  const bookingMutation = useCreateBookingUser();

  const editBookingMutation = useEditBookingUserById();

  type FormData = z.infer<typeof CreateUserSchema>;

  const steps = ["User Details", "Create Attendee"];

  const stepperRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const [userUuid, setUserUuid] = useState("");

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(CreateUserSchema),
  });

  const handleCancel = () => {
    navigate("/admin/activities?view=activities");
  };

  const handleNextStep = () => {
    setCurrentStep((currentStep + 1) as CreateUserSteps);
  };

  const handlePreviousStep = () => {
    setCurrentStep((currentStep - 1) as CreateUserSteps);
  };

  const handleFinish = () => {
    reset();
    setUserUuid("");
    navigate("/admin/users");
  };

  function handleSuccess(data: any) {
    toast({
      title: `Booking user created`,
      description: `Booking user has been created successfully.`,
      variant: "success",
    });
    setUserUuid(data.uuid);
    queryClient.invalidateQueries(USER_KEYS.user);
    handleNextStep();
    // reset();
  }

  function handleEditSuccess() {
    toast({
      title: `Booking user edited`,
      description: `Booking user has been edited successfully.`,
      variant: "success",
    });
    queryClient.invalidateQueries(USER_KEYS.user);
    handleNextStep();
    // reset();
  }

  function handleError(error: unknown) {
    if (axios.isAxiosError(error)) {
      toast({
        variant: "destructive",
        title: "Error",
        description: error?.response?.data?.message,
      });
    } else {
      toast({
        variant: "destructive",
        title: "Error",
        description: `User couldn't be created`,
      });
    }
  }

  const onSubmit = async (data: FormData) => {
    const baseData = {
      person: {
        firstName: data.firstName,
        lastName: data.lastName,
        gender: data.gender,
        dateOfBirth: data.dateOfBirth,
      },
      credentials: {
        email: data.email.toLowerCase(),
        password: data.password,
      },
    };

    if (userUuid && userUuid.length > 0) {
      await editBookingMutation.mutateAsync(
        { userUuid: userUuid, data: { person: baseData.person } },
        { onSuccess: handleEditSuccess, onError: handleError }
      );
      return;
    }

    await bookingMutation.mutateAsync(baseData, {
      onSuccess: handleSuccess,
      onError: handleError,
    });
  };

  const ComponentMapping = {
    [CreateUserSteps.UserDetails]: (
      <UserDetails
        register={register}
        handleSubmit={handleSubmit}
        setValue={setValue}
        errors={errors}
        onSubmit={onSubmit}
        userUuid={userUuid}
      />
    ),
    [CreateUserSteps.CreateAttendee]: (
      <CreateAttendeeStep
        handleFinish={handleFinish}
        handlePreviousStep={handlePreviousStep}
        userUuid={userUuid}
      />
    ),
  };

  return (
    <>
      <div className="max-w-7xl w-[93%] mx-auto md:px-4 px-0 lg:px-8">
        <div className="flex md:flex-col flex-col">
          <div className="w-full hidden md:block">
            <div className="sticky top-0 overflow-y-auto">
              <div className="py-4">
                <h1 className="text-2xl font-semibold text-gray-900 mb-6 flex items-center gap-2">
                  <ChevronLeft
                    onClick={handleCancel}
                    className="w-5 h-6 font-bold cursor-pointer"
                  />
                  Create User
                </h1>
                <div ref={stepperRef}>
                  <UserStepper
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    isEditMode={false}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="md:hidden block mt-4 mb-2">
            <UserStepperMobile
              steps={steps}
              currentStep={currentStep}
              complete={false}
            />
          </div>
          <div className="w-full flex items-center justify-between">
            {ComponentMapping[currentStep as keyof typeof ComponentMapping]}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateUserStepForm;
