import { API } from "../../../config/api";
import {
  CreateActivityDto,
  UpdateActivityDto,
  UpdateActivityWithStateDto,
} from "../../../types/activityType";
import {
  CreatePackagedSessionDto,
  CreateSessionDto,
} from "../../../types/sessionTypes";
import { useAxiosPrivate } from "../axios/private";
import useAxiosPublic from "../axios/public";

type QueryType = "public" | "admin";

export const ACTIVITY_KEYS = {
  all: () => ["activities"] as const,
  detail: (type: QueryType, uuid: string) =>
    ["activities", type, uuid] as const,
};
export const SESSION_KEYS = {
  all: () => ["sessions"] as const,
  allByActivity: (uuid: string) => ["sessions", "activity", uuid] as const,
  detail: (type: QueryType, uuid: string) => ["sessions", type, uuid] as const,
};

export const PACKAGED_SESSION_KEYS = {
  all: () => ["packaged-sessions"] as const,
  allByActivity: (uuid: string) => ["packaged-sessions", uuid] as const,
  detail: (type: QueryType, uuid: string) =>
    ["packaged-sessions", type, uuid] as const,
};

interface EditSessionData extends CreateSessionDto {
  uuid: string;
}

export const useActivitiesApi = () => {
  const privateInstance = useAxiosPrivate();
  const publicInstance = useAxiosPublic();

  return {
    public: {
      getAll: async (venueUuid?: string) => {
        const url = venueUuid
          ? `${API.GET_ACTIVITIES}?venueUuid=${venueUuid}`
          : API.GET_ACTIVITIES;
        const response = await publicInstance.get(url);

        return response.data;
      },
      getById: async (id: string) => {
        const response = await publicInstance.get(
          `${API.GET_ACTIVITIES}/${id}`
        );
        return response.data;
      },
      getSessionsForActivity: async (uuid: string) => {
        const response = await publicInstance.get(
          `${API.GET_ACTIVITIES}/${uuid}/session`
        );
        return response.data;
      },
      getPackagedSessionsForActivity: async (uuid: string) => {
        const response = await publicInstance.get(
          `${API.GET_ACTIVITIES}/${uuid}/session-package`
        );
        return response.data;
      },
      validateCartSessions: async (sessionUuidList: string[]) => {
        const response = await publicInstance.post(`${API.SESSIONS}/validate`, {
          sessionUuidList,
        });
        return response.data;
      },
    },
    admin: {
      getAll: async () => {
        const response = await privateInstance.get(API.GET_ACTIVITIES);
        return response.data;
      },
      getById: async (id: string) => {
        const response = await privateInstance.get(
          `${API.GET_ACTIVITIES}/${id}`
        );
        return response.data;
      },

      getSessionsForActivity: async (uuid: string) => {
        const response = await privateInstance.get(
          `${API.GET_ACTIVITIES}/${uuid}/session`
        );
        return response.data;
      },

      create: async (data: CreateActivityDto) => {
        const response = await privateInstance.post(API.GET_ACTIVITIES, data);
        return response.data;
      },
      createDraft: async (data: CreateActivityDto) => {
        const response = await privateInstance.post(API.ACTIVITY_DRAFT, data);
        return response.data;
      },
      enableActivity: async (id: string) => {
        const response = await privateInstance.put(
          `${API.GET_ACTIVITIES}/${id}/enable`
        );
        return response.data;
      },
      update: async ({
        data,
        activityUuid,
      }: {
        data: UpdateActivityWithStateDto;
        activityUuid: string;
      }) => {
        const response = await privateInstance.put(
          `${API.GET_ACTIVITIES}/${activityUuid}`,
          data
        );
        return response.data;
      },
      updateActivityWithState: async ({
        data,
        activityUuid,
      }: {
        data: UpdateActivityWithStateDto;
        activityUuid: string;
      }) => {
        const response = await privateInstance.put(
          `${API.GET_ACTIVITIES}/v2/${activityUuid}/state`,
          data
        );
        return response.data;
      },

      updateActivity: async ({
        data,
        activityUuid,
      }: {
        data: UpdateActivityDto;
        activityUuid: string;
      }) => {
        const response = await privateInstance.put(
          `${API.GET_ACTIVITIES}/v2/${activityUuid}`,
          data
        );
        return response.data;
      },

      deleteActivityById: async (id: string) => {
        const response = await privateInstance.delete(
          `${API.GET_ACTIVITIES}/${id}`
        );
        return response.data;
      },

      archiveActivity: async (uuid: string) => {
        const response = await privateInstance.put(
          `${API.GET_ACTIVITIES}/${uuid}/archive`
        );
        return response.data;
      },

      disableActivity: async (uuid: string) => {
        const response = await privateInstance.put(
          `${API.GET_ACTIVITIES}/${uuid}/disable`
        );
        return response.data;
      },

      createSession: async (data: CreateSessionDto) => {
        const response = await privateInstance.post(
          `${API.GET_ACTIVITIES}/${data.activityUuid}/session`,
          data
        );
        return response.data;
      },

      editSession: async (data: EditSessionData) => {
        const response = await privateInstance.put(
          `/session/${data.uuid}`,
          data
        );
        return response.data;
      },

      getAllSessions: async () => {
        const response = await privateInstance.get(API.SESSIONS);
        return response.data;
      },

      getSessionById: async (id: string) => {
        const response = await privateInstance.get(`${API.SESSIONS}/${id}`);
        return response.data;
      },

      deleteSession: async (id: string) => {
        const response = await privateInstance.delete(`${API.SESSIONS}/${id}`);
        return response.data;
      },

      createPackagedSession: async (data: CreatePackagedSessionDto) => {
        const response = await privateInstance.post(`/session-package`, data);
        return response.data;
      },

      updatePackagedSessionById: async ({
        data,
        id,
      }: {
        data: CreatePackagedSessionDto;
        id: string;
      }) => {
        const response = await privateInstance.put(
          `/session-package/${id}`,
          data
        );
        return response.data;
      },

      getPackagedSessionById: async (id: string) => {
        const response = await privateInstance.get(`/session-package/${id}`);
        return response.data;
      },

      getAllPackagedSessions: async () => {
        const response = await privateInstance.get(`/session-package`);
        return response.data;
      },

      getAllPackagedSessionByActivity: async (activityUuid: string) => {
        const response = await privateInstance.get(
          `activity/${activityUuid}/session-package`
        );
        return response.data;
      },
    },
  };
};
