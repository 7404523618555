import { PublicActivitiesSteps } from "@/constants/consts";
import { Check, Dot } from "lucide-react";

const PublicActivitiesStepper = ({
  currentStep,
  complete,
}: {
  currentStep: number;
  complete: boolean;
}) => {
  // const getStepName = (step: number) => {
  //   switch (step) {
  //     case 1:
  //       return "Index";
  //     case 2:
  //       return "Select Location";
  //     case 3:
  //       return "Select Activity";
  //     case 4:
  //       return "Select Session";
  //     default:
  //       return "Checkout";
  //   }
  // };

  const steps = [
    "Index",
    "SelectLocation",
    "SelectActivity",
    "SelectSessions",
    "Checkout",
  ];

  return (
    <div className="flex bg-white rounded-2xl whitespace-nowrap items-center px-3 sm:px-5 min-h-[3.5rem]">
      <div className="flex items-center">
        {/* Mobile short version */}
        <p className="sm:hidden text-[#798096] text-sm">
          {currentStep}/{steps?.length}
        </p>

        {/* Desktop long version */}
        <p className="hidden sm:block text-[#798096] text-sm">
          Step 0{currentStep} of 0{steps?.length}
        </p>

        <Dot />
      </div>

      <div className="flex items-center gap-2">
        {steps.map((_, i) => {
          return (
            <div
              key={i}
              className={`h-7 rounded-full w-7 border flex items-center justify-center border-[#CFD6DC] 
                ${currentStep === i + 1 && ""} 
                ${
                  (i + 1 < currentStep ||
                    complete ||
                    (i + 1 === PublicActivitiesSteps.Checkout &&
                      currentStep === PublicActivitiesSteps.Checkout)) &&
                  "border-none bg-primary_colour"
                }
                ${
                  currentStep < i + 1
                    ? "border border-[#CFD6DC] bg-[#ABB7C21A]"
                    : "text-white"
                }`}
            >
              {(i + 1 < currentStep ||
                complete ||
                (i + 1 === PublicActivitiesSteps.Checkout &&
                  currentStep === PublicActivitiesSteps.Checkout)) && (
                <Check
                  size={16}
                  className={`${
                    (i + 1 < currentStep || complete) && "text-white"
                  }`}
                />
              )}

              {currentStep === i + 1 &&
                currentStep !== PublicActivitiesSteps.Checkout && (
                  <div className="h-3.5 w-3.5 rounded-full bg-primary_colour"></div>
                )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PublicActivitiesStepper;
