import { CreateUserSteps } from "@/constants/consts";
import React from "react";

const options = ["User Details", "Create Attendee"];

interface UserStepperProps {
  currentStep: CreateUserSteps;
  setCurrentStep: (step: CreateUserSteps) => void;
  isEditMode: boolean;
}

const UserStepper: React.FC<UserStepperProps> = ({
  currentStep,
  setCurrentStep,
  isEditMode,
}) => {
  const getStepName = (step: number) => {
    switch (step) {
      case 1:
        return "User Details";
      case 2:
        return "Create Attendee";
      default:
        return "User Details";
    }
  };

  function handleGoToStep(step: CreateUserSteps) {
    if (isEditMode) {
      setCurrentStep(step);
    }
  }

  return (
    <div className="flex bg-white py-4 px-6 rounded-2xl w-full gap-3">
      {options.map((option: string, index) => {
        const isActive = getStepName(currentStep) === option;
        return (
          <div
            key={index}
            onClick={() => handleGoToStep(index + 2)}
            className={`flex-1 flex items-center justify-center px-4 py-3 rounded-2xl
          ${isActive ? "bg-[#4F6DF5] text-white" : "bg-[#F5F6FA] text-gray-700"} ${isEditMode && "cursor-pointer"}`}
          >
            <div className="text-sm">{option}</div>
          </div>
        );
      })}
    </div>
  );
};

export default UserStepper;
