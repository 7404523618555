import { cartAtom, selectedAttendeeAtom } from "@/atom/atom";
import {
  AddOnType,
  CartSessionType,
  GroupedSession,
  PackagedSession,
  SessionType,
} from "@/types/sessionTypes";
import { useAtom, useSetAtom } from "jotai";
import { jwtDecode } from "jwt-decode";
import { useEffect, useMemo, useState } from "react";

const useCartManagement = () => {
  const [cart, setCart] = useAtom(cartAtom);
  const [showOptionToIncrement, setShowOptionToIncrement] = useState({});
  const [groupedSessions, setGroupedSessions] = useState<GroupedSession>({});
  const [activeSessionId, setActiveSessionId] = useState<string | null>(null);
  const setSelectedAttendeeAtom = useSetAtom(selectedAttendeeAtom);

  const addToCart = (
    sessionToAdd: SessionType | PackagedSession,
    selectedAddons?: AddOnType[]
  ) => {
    setActiveSessionId(sessionToAdd.uuid);
    setCart((prevCart) => {
      const existingSession = prevCart.find(
        (session) => session.uuid === sessionToAdd.uuid
      );

      let updatedCart;
      if (existingSession) {
        updatedCart = prevCart.map((session) =>
          session.uuid === sessionToAdd.uuid
            ? {
                ...session,
                quantity: (session.quantity ?? 0) + 1,
                selectedAddons: session.selectedAddons,
                // Add new instance with same addons as first instance
                instances: [
                  ...(session.instances || []),
                  {
                    instanceId: `${session.uuid}-${session.instances?.length || 0}`,
                    selectedAddons:
                      session.instances?.[0]?.selectedAddons || [],
                    attendeeUuid: undefined,
                  },
                ],
              }
            : session
        );
      } else {
        console.log("sessionToAdd", sessionToAdd);

        let cartSession: CartSessionType;

        if ((<PackagedSession>sessionToAdd).sessions !== undefined) {
          cartSession = {
            uuid: (<PackagedSession>sessionToAdd).uuid,
            name: (<PackagedSession>sessionToAdd).name,
            activityUuid: undefined,
            activityName: "packaged",
            startTime: undefined,
            endTime: undefined,
            venue: undefined,
            addons: undefined,
            selectedAddons: undefined,
            totalAvailableSpaces: (<PackagedSession>sessionToAdd)
              .totalAvailableSpaces,
            allocatedSpaces: (<PackagedSession>sessionToAdd).allocatedSpaces,
            type: "packaged",
            attendeeList: [],
            price: {
              ...(<PackagedSession>sessionToAdd).price,
              amount: (<PackagedSession>sessionToAdd).price.amount,
            },
            quantity: 1,
            user: "guest",
            // Initialize first instance with selected addons
            instances: [
              {
                instanceId: `${sessionToAdd.uuid}-0`,
                selectedAddons: selectedAddons || [],
                attendeeUuid: undefined,
              },
            ],
            sessions: (<PackagedSession>sessionToAdd).sessions,
          };
        } else {
          cartSession = {
            uuid: (<SessionType>sessionToAdd).uuid,
            name: (<SessionType>sessionToAdd).name,
            activityUuid: (<SessionType>sessionToAdd).activityUuid,
            activityName: (<SessionType>sessionToAdd).activityName,
            startTime: (<SessionType>sessionToAdd).startTime,
            endTime: (<SessionType>sessionToAdd).endTime,
            venue: {
              ...(<SessionType>sessionToAdd).venue,
              uuid: { value: (<SessionType>sessionToAdd).venue.uuid },
            },
            addons: (<SessionType>sessionToAdd).addons,
            selectedAddons: selectedAddons || [],
            totalAvailableSpaces: (<SessionType>sessionToAdd)
              .totalAvailableSpaces,
            allocatedSpaces: (<SessionType>sessionToAdd).allocatedSpaces,
            type: "individual",
            attendeeList: [],
            price: {
              ...(<SessionType>sessionToAdd).price,
              amount: (<SessionType>sessionToAdd).price.amount,
            },
            quantity: 1,
            user: "guest",
            // Initialize first instance with selected addons
            instances: [
              {
                instanceId: `${sessionToAdd.uuid}-0`,
                selectedAddons: selectedAddons || [],
                attendeeUuid: undefined,
              },
            ],
          };
        }

        updatedCart = [...prevCart, cartSession];
        saveCartToLocalStorage(updatedCart);
      }

      return updatedCart;
    });
  };

  const removeCompletelyFromCart = (sessionUuid: string) => {
    setCart((prevCart) => {
      const updatedCart = prevCart.filter(
        (session) => session.uuid !== sessionUuid
      );
      saveCartToLocalStorage(updatedCart);
      return updatedCart;
    });
    setSelectedAttendeeAtom([]);
  };

  const saveCartToLocalStorage = (updatedCart: CartSessionType[]) => {
    const user = localStorage.getItem("token");
    if (!user) {
      const cartWithUserEmail = updatedCart.map((item) => ({
        ...item,
        user: "guest",
      }));
      localStorage.setItem("cart", JSON.stringify(cartWithUserEmail));
      return;
    }
    const userEmail = jwtDecode(user).sub;
    const cartWithUserEmail = updatedCart.map((item) => ({
      ...item,
      user: userEmail,
    }));
    localStorage.setItem("cart", JSON.stringify(cartWithUserEmail));
  };

  const clearCart = () => {
    localStorage.removeItem("cart");

    setCart([]);
  };

  const verifyCartOwnership = (userEmail?: string) => {
    const localCart = getCartFromLocalStorage();
    if (localCart.length === 0) {
      return;
    }

    const firstSession = localCart[0];
    const cartUser = firstSession.user;

    if (cartUser === "guest") {
      return;
    }

    let email: string | undefined = undefined;

    if (userEmail) {
      email = userEmail;
    } else {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found, cannot verify cart ownership");
        return;
      }

      try {
        const decodedToken = jwtDecode(token) as { sub: string };
        email = decodedToken.sub;
      } catch (error) {
        console.error("Error decoding token:", error);
        return;
      }
    }

    if (cartUser !== email) {
      localStorage.removeItem("cart");
      setCart([]);
    } else {
    }
  };

  const getCartFromLocalStorage = () => {
    const localCart = localStorage.getItem("cart");

    return localCart ? JSON.parse(localCart) : [];
  };

  const removeShowOptions = (sessionUUID: string) => {
    setShowOptionToIncrement((prev) => {
      return {
        ...prev,
        [sessionUUID]: false,
      };
    });
  };

  const removeFromCart = (uuid: string) => {
    setActiveSessionId(uuid);
    setCart((prevCart) => {
      const updatedCart = prevCart.reduce(
        (acc: CartSessionType[], session: CartSessionType) => {
          if (session.uuid === uuid) {
            if (session.quantity === 1) {
              removeShowOptions(session.uuid);
              return acc; // Remove session completely
            }

            if (session.quantity > 1) {
              // Remove the last instance when reducing quantity
              const updatedInstances = session.instances?.slice(0, -1) || [];

              acc.push({
                ...session,
                quantity: session.quantity - 1,
                instances: updatedInstances,
              });
            }
          } else {
            acc.push(session);
          }
          return acc;
        },
        []
      );

      saveCartToLocalStorage(updatedCart);
      return updatedCart;
    });
    setSelectedAttendeeAtom([]);
  };

  const getSessionQuantities = (
    cart: CartSessionType[]
  ): { [key: string]: number } => {
    const quantities: { [key: string]: number } = {};
    cart.forEach((session) => {
      quantities[session.uuid] =
        (quantities[session.uuid] || 0) + session.quantity;
    });
    return quantities;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setActiveSessionId(null);
    }, 2000);

    return () => clearTimeout(timer);
  }, [activeSessionId]);

  const aggregatedCartItems = useMemo(() => {
    const aggregation = cart.reduce<{ [key: string]: CartSessionType }>(
      (acc, session) => {
        if (acc[session.uuid]) {
          acc[session.uuid].quantity += session.quantity;
        } else {
          acc[session.uuid] = { ...session };
        }
        return acc;
      },
      {}
    );

    return Object.values(aggregation);
  }, [cart]);

  const totalAmount = useMemo(() => {
    return cart.reduce(
      (acc, item) => acc + item.price?.amount * (item.quantity ?? 0),
      0
    );
  }, [cart]);

  const totalSessionInCart = useMemo(() => {
    return cart.reduce((acc, item) => acc + (item.quantity ?? 0), 0);
  }, [cart]);

  useEffect(() => {
    const grouped: GroupedSession = cart.reduce(
      (acc: GroupedSession, session: CartSessionType, index: number) => {
        const { activityName, price } = session;

        if (!acc[activityName]) {
          acc[activityName] = {
            activityName,
            sessions: [],
            totalQuantity: 0,
            totalPrice: 0,
          };
        }

        const enhancedSession = {
          ...session,
          index: index,
          uniqueKey: `${session.uuid}-${index}`, // Generate uniqueKey here
        };

        acc[activityName].sessions.push(enhancedSession);
        acc[activityName].totalQuantity += 1;
        acc[activityName].totalPrice += price.amount;

        return acc;
      },
      {}
    );

    setGroupedSessions(grouped);
  }, [cart]);

  return {
    cart,
    addToCart,
    removeFromCart,
    getCartFromLocalStorage,
    saveCartToLocalStorage,
    showOptionToIncrement,
    setShowOptionToIncrement,
    getSessionQuantities,
    removeCompletelyFromCart,
    aggregatedCartItems,
    totalAmount,
    totalSessionInCart,
    clearCart,
    activeSessionId,
    groupedSessions,
    setGroupedSessions,
    verifyCartOwnership,
  };
};

export default useCartManagement;
