import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';
import { getCurrencySymbol } from '../../lib/utils';
import { AddOnType } from '@/types/sessionTypes';
import { Tag } from 'lucide-react';
import { Separator } from '@/components/ui/separator';

interface AddonsModalProps {
  isOpen: boolean;
  onClose: () => void;
  addons: AddOnType[];
  onConfirm: (selectedAddons: AddOnType[]) => void;
  currency: string;
}

const AddonsModal = ({ isOpen, onClose, addons, onConfirm, currency }: AddonsModalProps) => {
  const [selectedAddons, setSelectedAddons] = React.useState<AddOnType[]>([]);
  const currencySymbol = getCurrencySymbol(currency);

  const handleAddonToggle = (addon: AddOnType) => {
    setSelectedAddons(prev => 
      prev.some(a => a.uuid === addon.uuid)
        ? prev.filter(a => a.uuid !== addon.uuid)
        : [...prev, addon]
    );
  };

  const handleConfirm = () => {
    onConfirm(selectedAddons);
    setSelectedAddons([]);
    onClose();
  };

  const totalPrice = selectedAddons.reduce((sum, addon) => sum + addon.priceAmount, 0);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle className="text-[#182143] font-light text-lg">
            Additional Options Available
          </DialogTitle>
        </DialogHeader>

        <div className="pt-2">
          {/* Add-ons List */}
          <div className="space-y-2">
            {addons.map((addon) => (
              <div 
                key={addon.uuid} 
                className={`flex items-start p-2 rounded-lg transition-all duration-200
                  ${selectedAddons.some(a => a.uuid === addon.uuid) 
                    ? 'bg-[#F9FAFC]' 
                    : 'hover:bg-gray-50'}`}
              >
                <Checkbox
                  id={addon.uuid}
                  checked={selectedAddons.some(a => a.uuid === addon.uuid)}
                  onCheckedChange={() => handleAddonToggle(addon)}
                  className="mt-1 mr-3"
                />
                <div className="flex flex-1 justify-between items-start">
                  <div className="space-y-0.5">
                    <Label
                      htmlFor={addon.uuid}
                      className="text-sm text-[#182143] font-light block"
                    >
                      {addon.name}
                    </Label>
                    <div className="flex items-center gap-1.5">
                      <Tag className="h-3 w-3 text-[#798096]" />
                      <span className="text-xs text-[#798096] font-light">
                        {currencySymbol}{addon.priceAmount}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Summary Section */}
          {selectedAddons.length > 0 && (
            <>
              <Separator className="my-3" />
              <div className="px-2">
                <div className="flex justify-between items-center text-xs text-[#798096] font-light mb-1">
                  <span>Selected Add-ons ({selectedAddons.length})</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-sm text-[#182143]">Total Add-ons</span>
                  <span className="text-sm font-medium text-[#182143]">
                    {currencySymbol}{totalPrice}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>

        <DialogFooter className="flex gap-2 mt-4">
          <Button
            variant="outline"
            className="flex-1 rounded-full text-xs h-9"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            className="flex-1 rounded-full text-xs h-9 text-white bg-primary_colour hover:bg-[#3552de]"
            onClick={handleConfirm}
          >
            Add to Cart
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddonsModal;