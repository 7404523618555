import { useEffect, useMemo, useState } from "react";
import { useAtom } from "jotai";
import { cartAtom, showOptionsToIncrementAtom } from "@/atom/atom";
import useCartManagement from "./useCartManagement";
import { parseSessionDate, UtcToLocal } from "@/lib/utils";
import { PublicSessionType, SessionType } from "@/types/sessionTypes";
import {
  useActivity,
  useGetPackagedSessionsForActivity,
  useSessionsForActivity,
} from "../core/api/activities";
import { useGetAllVenues } from "../core/api/organization/organization.queries";

const useActivityDetails = ({ activityId }: { activityId: string }) => {
  const [minValuePossible, setMinValuePossible] = useState(0);
  const [maxValuePossible, setMaxValuePossible] = useState(0);
  const [minValueSelected, set_minValueSelected] = useState(0);
  const [maxValueSelected, set_maxValueSelected] = useState(200);
  const [sessionTypeFilter, setSessionTypeFilter] = useState<string>("all");

  const { getCartFromLocalStorage } = useCartManagement();
  const [, setCart] = useAtom(cartAtom);
  const handleInput = (e: { minValue: number; maxValue: number }) => {
    set_minValueSelected(e.minValue);
    set_maxValueSelected(e.maxValue);
  };

  const [selectedDate, setSelectedDates] = useState([
    {
      startDate: null as Date | null,
      endDate: null as Date | null,
      key: "selection",
    },
  ]);

  const [venueFilter, setVenueFilter] = useState<string>("All Locations");

  const handleDateChange = (item: [Date, Date] | null) => {
    if (item) {
      setSelectedDates([
        {
          startDate: item[0],
          endDate: item[1],
          key: "selection",
        },
      ]);
    } else {
      setSelectedDates([]);
    }
  };

  const [showOptionToIncrement, setShowOptionToIncrement] = useAtom(
    showOptionsToIncrementAtom
  );

  const showOptions = (sessionUUID: string) => {
    setShowOptionToIncrement((prev) => {
      return {
        ...prev,
        [sessionUUID]: true,
      };
    });
  };

  const removeOption = (sessionUUID: string) => {
    setShowOptionToIncrement((prev) => {
      return {
        ...prev,
        [sessionUUID]: false,
      };
    });
  };

  const handleVenueChange = (value: string) => {
    setVenueFilter(value);
  };

  const { data: venues, isLoading: isVenuesLoading } =
    useGetAllVenues("public");

  const { data: activityData, isLoading: isActivityDataLoading } = useActivity(
    activityId,
    !!activityId,
    "public"
  );
  const { data: sessionData, isFetching: isSessionsLoading } =
    useSessionsForActivity(activityData?.uuid, !!activityData?.uuid, "public");

  const { data: packagedSessions, isLoading: isPackagedSessionsLoading } =
    useGetPackagedSessionsForActivity(activityData?.uuid, !!activityData?.uuid);

  useEffect(() => {
    const storedCart = getCartFromLocalStorage();
    setCart(storedCart);
  }, [setCart]);

  const filteredSessions = useMemo(() => {
    if (!sessionData) return [];

    let filteredSessionData = sessionData.filter(
      (session: PublicSessionType) => {
        const sessionStartDate = parseSessionDate(
          UtcToLocal(session.startTime) as string
        );
        // const sessionStartDate = UtcToLocal(session.startTime);
        const now = new Date();
        const sessionNotExpired = sessionStartDate > now;
        const meetsVenueAndPriceConditions =
          (venueFilter === "All Locations" ||
            session?.venue.uuid.value === venueFilter) &&
          session.price.amount >= minValueSelected &&
          session.price.amount <= maxValueSelected;

        if (selectedDate?.[0]?.startDate && selectedDate?.[0]?.endDate) {
          const filterStartDate = new Date(selectedDate[0].startDate);
          const filterEndDate = new Date(selectedDate[0].endDate);
          return (
            sessionNotExpired &&
            sessionStartDate >= filterStartDate &&
            sessionStartDate <= filterEndDate &&
            meetsVenueAndPriceConditions
          );
        }

        return sessionNotExpired && meetsVenueAndPriceConditions;
      }
    );

    return filteredSessionData.sort((a: string, b: string) =>
      new Date(a) > new Date(b) ? 1 : -1
    );
  }, [
    sessionData,
    selectedDate,
    venueFilter,
    minValueSelected,
    maxValueSelected,
  ]);

  function handleResetVenueAndDate() {
    setSelectedDates([
      {
        startDate: null as Date | null,
        endDate: null as Date | null,
        key: "selection",
      },
    ]);
    setVenueFilter("All Locations");
  }

  useEffect(() => {
    if (sessionData) {
      let minVal = 0;
      let maxVal = 0;

      sessionData.forEach((session: SessionType) => {
        let currentSessionPrice = session?.price?.amount;
        if (currentSessionPrice < minVal) {
          minVal = currentSessionPrice;
        } else if (currentSessionPrice > maxVal) {
          maxVal = currentSessionPrice;
        }
      });

      setMaxValuePossible(maxVal);
      setMinValuePossible(minVal);
    }
  }, [sessionData]);

  return {
    minValuePossible,
    maxValuePossible,
    minValueSelected,
    maxValueSelected,
    sessionTypeFilter,
    setSessionTypeFilter,
    handleInput,
    selectedDate,
    setSelectedDates,
    venueFilter,
    setVenueFilter,
    parseSessionDate,
    handleDateChange,
    showOptionToIncrement,
    showOptions,
    handleVenueChange,
    activityData,
    sessionData,
    isActivityDataLoading,
    venues,
    isVenuesLoading,
    isSessionsLoading,
    isPackagedSessionsLoading,
    filteredSessions,
    removeOption,
    handleResetVenueAndDate,
    packagedSessions,
  };
};

export default useActivityDetails;
