import React, { useState, useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import enGB from "@fullcalendar/core/locales/en-gb";
import { SessionType } from "@/types/sessionTypes";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { getCurrencySymbol } from "../../lib/utils";
import { CalendarIcon, MapPin } from "lucide-react";
import { CartIcon } from "../../assets/Icons/icon";
import useCartManagement from "@/hooks/useCartManagement";
import { EventContentArg, EventSourceInput } from "@fullcalendar/core/index.js";
import { startOfMonth } from "date-fns";

interface PublicSessionCalendarProps {
  sessions: SessionType[];
  isSessionsLoading: boolean;
  setCurrentMonthStartDate?: React.Dispatch<React.SetStateAction<Date>>;
}

function formatSessionTime(startTime: string, endTime: string): string {
  const startDateTime = new Date(startTime);
  const endDateTime = new Date(endTime);

  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };

  const formattedStartTime = startDateTime.toLocaleTimeString("en-US", options);
  const formattedEndTime = endDateTime.toLocaleTimeString("en-US", options);

  return `${formattedStartTime} - ${formattedEndTime}`;
}

const PublicSessionCalendar: React.FC<PublicSessionCalendarProps> = ({
  sessions,
  // isSessionsLoading,
  setCurrentMonthStartDate,
}) => {
  // if (isSessionsLoading) {
  //   return <Skeleton className="h-full w-full" />;
  // }

  const calendarRef = useRef<FullCalendar>(null);

  function handleMonthChange() {
    const calendarApi = calendarRef?.current?.getApi();
    const newDate = calendarApi?.getDate();
    console.log("newDate", newDate);
    setCurrentMonthStartDate &&
      newDate &&
      setCurrentMonthStartDate(startOfMonth(newDate));
  }

  const {
    addToCart,
    removeFromCart,
    getSessionQuantities,
    cart,
    setShowOptionToIncrement,
  } = useCartManagement();

  const [bookedSessions, setBookedSessions] = useState<{
    [key: string]: number;
  }>({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const quantities = getSessionQuantities(cart);
    setBookedSessions(quantities);
  }, [cart]);

  const convertUTCToLocalTime = (utcTimeString: string): string => {
    const utcDate = new Date(utcTimeString + "Z");

    const year = utcDate.getFullYear();
    const month = String(utcDate.getMonth() + 1).padStart(2, "0");
    const day = String(utcDate.getDate()).padStart(2, "0");
    const hours = String(utcDate.getHours()).padStart(2, "0");
    const minutes = String(utcDate.getMinutes()).padStart(2, "0");
    const seconds = String(utcDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(utcDate.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

  const newSessionsData =
    sessions?.map((session) => {
      const startTime = convertUTCToLocalTime(session.startTime);

      const endTime = convertUTCToLocalTime(session.endTime);

      return {
        ...session,
        startTime,
        endTime,
      };
    }) || [];

  const events: EventSourceInput = newSessionsData.map((session) => ({
    id: session.uuid,
    title: session.name,
    start: session.startTime,
    end: session.endTime,
    extendedProps: {
      session: session,
    },
  }));

  const handleAddToCart = (session: SessionType) => {
    addToCart(session);
    setShowOptionToIncrement((prev) => ({ ...prev, [session.uuid]: true }));

    setBookedSessions((prev) => ({
      ...prev,
      [session.uuid]: (prev[session.uuid] || 0) + 1,
    }));
  };

  const handleRemoveFromCart = (sessionUuid: string) => {
    removeFromCart(sessionUuid);

    setBookedSessions((prev) => ({
      ...prev,
      [sessionUuid]: Math.max((prev[sessionUuid] || 0) - 1, 0),
    }));
  };

  const renderEventContent = (eventInfo: EventContentArg) => {
    const { event } = eventInfo;
    const session = event.extendedProps.session;
    const sessionCountInCart = bookedSessions[session.uuid] || 0;
    const availableSpaces =
      session.totalAvailableSpaces - session.allocatedSpaces;
    const isFull = sessionCountInCart >= availableSpaces;

    return (
      <Popover>
        <PopoverTrigger asChild>
          <div className="flex flex-col h-full w-full p-1 overflow-hidden cursor-pointer">
            <div className="font-semibold text-xs truncate text-white">
              {event.title}
            </div>
            {/* <div className="text-xs truncate text-white opacity-80">
              {session.activityName}
            </div> */}
          </div>
        </PopoverTrigger>
        <PopoverContent className="md:w-[16.2rem] rounded-2xl p-0">
          <SessionCardContent
            session={session}
            addToCart={handleAddToCart}
            removeFromCart={handleRemoveFromCart}
            sessionCountInCart={sessionCountInCart}
            isFull={isFull}
            setShowOptionToIncrement={setShowOptionToIncrement}
          />
        </PopoverContent>
      </Popover>
    );
  };

  return (
    <div className="h-full w-full flex-grow">
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView={"dayGridMonth"}
        locale={enGB}
        headerToolbar={{
          left: "title",
          right: windowWidth < 850 ? "" : "dayGridMonth prev,next",
        }}
        events={events}
        eventContent={renderEventContent}
        // height={windowWidth < 850 ? "auto" : "580px"}
        height={"100%"}
        slotDuration="00:30:00"
        slotMinTime="06:00:00"
        slotMaxTime="22:00:00"
        allDaySlot={false}
        fixedWeekCount={false}
        eventTimeFormat={{
          hour: "numeric",
          minute: "2-digit",
          meridiem: "short",
        }}
        buttonText={{
          today: "Today",
          month: "This Month",
          week: "Week",
          day: "Day",
        }}
        eventClassNames={(arg) => [
          "border-none",
          "rounded-lg",
          "shadow-sm",
          "transition-all",
          "hover:shadow-md",
          "cursor-pointer",
          bookedSessions[arg.event.id] ? "bg-green-500" : "bg-primary_colour",
        ]}
        slotLabelFormat={{
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        }}
        dayHeaderFormat={{
          weekday: "short",
          omitCommas: true,
        }}
        slotEventOverlap={false}
        eventMaxStack={2}
        moreLinkContent={(args) => `+${args.num} more`}
        moreLinkClassNames={[
          "font-bold",
          "text-primary_colour",
          "hover:underline",
        ]}
        dayHeaderClassNames="font-semibold text-gray-700 uppercase"
        slotLabelClassNames="font-medium text-gray-500"
        viewClassNames="border border-[#e2e8f0] overflow-hidden"
        dayCellClassNames="hover:bg-gray-50"
        titleFormat={{ year: "numeric", month: "long" }}
        datesSet={() => handleMonthChange()}
      />
    </div>
  );
};

const SessionCardContent: React.FC<{
  session: SessionType;
  addToCart: (session: SessionType) => void;
  removeFromCart: (sessionUuid: string) => void;
  sessionCountInCart: number;
  isFull: boolean;
  setShowOptionToIncrement: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
}> = ({
  session,
  addToCart,
  removeFromCart,
  sessionCountInCart,
  isFull,
  setShowOptionToIncrement,
}) => {
  const handleAddToCart = () => {
    addToCart(session);
    setShowOptionToIncrement((prev) => ({ ...prev, [session.uuid]: true }));
  };

  return (
    <div className="md:w-[16.2rem] h-fit rounded-2xl p-4 flex flex-col gap-2 bg-white shadow-md">
      <div className="flex flex-col gap-2 text-sm">
        <div className="flex items-center gap-1 text-xs">
          <p className="text-[#182143] font-light">Individual Session</p>
        </div>
        <h1 className="text-base md:text-xl flex items-center pl-1 text-[#182143]">
          {session.name}
        </h1>
        <div className="flex items-center gap-1">
          <CalendarIcon className="text-[#798096] h-4" />
          <p className="text-[#798096] font-light text-xs">
            {new Date(session.startTime).toLocaleDateString("en-GB", {
              year: "numeric",
              month: "short",
              day: "2-digit",
            })}{" "}
            {formatSessionTime(session.startTime, session.endTime)}
          </p>
        </div>
        <div className="flex items-center gap-1">
          <MapPin className="text-[#798096] h-4" />
          <p className="text-[#798096] font-light text-xs">
            {session.venue.name}
          </p>
        </div>
      </div>
      <div className="flex justify-between mt-4 gap-2">
        <div>
          <p className="text-[#182143] font-normal text-">
            {getCurrencySymbol(session.price.currency)}
            {session.price.amount}
          </p>
          <p className="text-xs font-light text-[#798096]">per attendee</p>
        </div>
        <div className="flex items-center gap-2">
          <div className="relative">
            {sessionCountInCart > 0 ? (
              <div className="ml-2">
                <div className="inline-flex">
                  <button
                    className={`w-8 flex items-center relative justify-center rounded-l-md hover:bg-[#3552de] bg-primary_colour text-white px-4 py-2 text-sm font-medium`}
                    aria-label="Remove"
                    onClick={() => removeFromCart(session.uuid)}
                  >
                    -
                  </button>
                  <span className="w-8 flex items-center justify-center py-2 bg-gray-100 text-sm font-medium text-gray-700">
                    {sessionCountInCart}
                  </span>
                  <button
                    disabled={isFull}
                    className={`w-8 flex items-center relative justify-center rounded-r-md px-4 py-2 text-sm font-medium ${
                      isFull
                        ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                        : "bg-primary_colour hover:bg-[#3552de] text-white"
                    }`}
                    aria-label="Add"
                    onClick={() => addToCart(session)}
                  >
                    +
                  </button>
                </div>
              </div>
            ) : (
              <div className="ml-2">
                <button
                  onClick={handleAddToCart}
                  className="py-2 px-2 md:px-4 text-xs flex items-center gap-2 hover:bg-[#3552de] bg-primary_colour text-white rounded-3xl"
                >
                  Add to cart <CartIcon />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicSessionCalendar;
