import { useGetAllVenues } from "../../../core/api/organization/organization.queries";
import { VenueType } from "../../../types/addressTypes";
import { getImageLink } from "../../../utils/helper";
import { getCountryName } from "../../../lib/utils";
import { useActivities } from "../../../core/api/activities";
import { PublicActivityType } from "../../../types/publicActivityType";
import PublicActivitiesStepper from "../PublicActivitiesStepper";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import Map, { Marker, NavigationControl } from "react-map-gl/mapbox";
import "mapbox-gl/dist/mapbox-gl.css";
import { Skeleton } from "../../TableSkeleton/Skeleton";
import { useState } from "react";

const SelectLocation = () => {
  const navigate = useNavigate();

  const [isMapLoading, setIsMapLoading] = useState(true);

  const { data: venueData, isLoading: isLoadingVenueData } =
    useGetAllVenues("public");

  const { data: activityData } = useActivities(undefined, "public");

  function handleSelectLocation(venueUuid: string) {
    navigate(`/select-activity?id=${venueUuid}`);
  }

  function handleSkip() {
    handleSelectLocation("all");
  }

  function handleGoBack() {
    navigate(-1);
  }

  function renderSkipButton() {
    return (
      <div
        onClick={handleSkip}
        className="group relative w-fit py-2 px-6 text-sm flex items-center gap-2 whitespace-nowrap hover:bg-[#3552de] bg-primary_colour text-white rounded-lg disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-not-allowed cursor-pointer"
      >
        Skip
      </div>
    );
  }

  return (
    <div
      className={`flex flex-col w-full h-[calc(100vh-70.4px-48.8px)] overflow-clip relative`}
    >
      <div className="flex w-full justify-between px-4 sm:px-6 pt-4 pb-4">
        <div className="flex items-center rounded-md justify-between w-[100%]">
          <div className="flex items-center lg:gap-3 gap-2">
            <button
              onClick={handleGoBack}
              className="h-8 w-8 flex items-center justify-center rounded-full hover:bg-[#3552de]  bg-primary_colour"
            >
              <ArrowLeft className="text-white" />
            </button>
            <h2 className="text-[#182143] lg:text-xl text-lg">
              <span className="inline-block md:hidden">Select Location</span>
              <span className="hidden md:inline-block">Select Location</span>
            </h2>
          </div>
        </div>
        <PublicActivitiesStepper currentStep={2} complete={false} />
      </div>
      <div className="flex justify-start gap-6 overflow-y-auto px-4 md:pl-4 md:pr-0 flex-1">
        <div className="w-full md:w-1/2 relative">
          <div className="flex flex-col gap-3 md:gap-0 h-[95%] max-h-full overflow-y-auto pb-4 md:pb-8">
            {isLoadingVenueData && (
              <>
                <Skeleton className="h-32 w-full mb-4" />
                <Skeleton className="h-32 w-full mb-4" />
                <Skeleton className="h-32 w-full mb-4" />
              </>
            )}
            {!isLoadingVenueData &&
              venueData &&
              venueData.map((venue: VenueType) => {
                const venueActivities =
                  activityData &&
                  (activityData as PublicActivityType[]).filter((activity) =>
                    activity.locations
                      .map((location) => location.uuid.value)
                      .includes(venue.uuid)
                  );

                return (
                  <>
                    <div
                      onClick={() => handleSelectLocation(venue?.uuid)}
                      className="flex h-fit py-4 gap-4 hover:bg-gray-100 cursor-pointer items-center flex-col bg-white rounded-xl shadow-md lg:rounded-none lg:shadow-none"
                    >
                      <div className="flex flex-col w-full gap-4 lg:flex-row lg:items-center">
                        <div className="flex flex-col w-full lg:flex-row lg:w-1/2 lg:px-2">
                          <div className="w-full h-[180px] lg:min-w-[150px] lg:h-[95px] lg:w-auto">
                            <img
                              src={
                                venue?.imageUrl
                                  ? getImageLink(venue?.imageUrl)
                                  : "/fallback_activity_crop.png"
                              }
                              loading="lazy"
                              alt={venue?.name}
                              className="w-full h-full object-cover min-w-0 min-h-0 rounded-md"
                            />
                          </div>

                          {/* Venue name and address - below image on mobile, beside image on desktop */}
                          <div className="flex flex-col mt-3 px-4 lg:px-0 lg:mt-0 lg:ml-4">
                            <div className="text-sm font-medium">
                              {venue.name}
                            </div>
                            <div className="flex flex-col text-gray-500 font-light text-[13px] leading-snug mt-1">
                              <div>
                                {venue.address.line1}, {venue.address.line2}
                              </div>
                              <div>{getCountryName(venue.address.country)}</div>
                              <div>{venue.address.postCode}</div>
                            </div>
                          </div>
                        </div>

                        {/* Vertical divider - visible only on desktop */}
                        <div className="bg-gray-600 h-full w-[1px] opacity-15 hidden lg:block"></div>

                        {/* Activities list */}
                        <div className="w-full lg:w-1/2 flex flex-wrap gap-1 h-fit px-4 lg:px-0">
                          {venueActivities &&
                            (venueActivities as PublicActivityType[]).map(
                              (activity) => (
                                <span
                                  key={activity.uuid.value}
                                  className="px-2 py-1 rounded-full text-xs font-light bg-gray-200 text-gray-700 h-fit"
                                >
                                  {activity?.name}
                                </span>
                              )
                            )}
                        </div>
                      </div>
                    </div>
                    <hr className="bg-gray-600 hidden lg:block" />
                  </>
                );
              })}
          </div>
          {/* <div className="hidden md:flex items-center w-full justify-end p-4 md:p-2">
            <div
              onClick={handleSkip}
              className="underline text-primary_colour text-[15px] cursor-pointer"
            >
              Skip this step
            </div>
          </div> */}
          <div className="absolute bottom-0 left-0 w-full hidden md:flex justify-end items-center py-3 px-6 bg-white border-t">
            <div
              onClick={handleSkip}
              className="underline text-primary_colour text-[15px] cursor-pointer"
            >
              Skip this step
            </div>
          </div>
        </div>
        <div className="hidden md:block w-1/2 h-full overflow-hidden">
          {isMapLoading && <Skeleton className="h-full w-full" />}
          <Map
            mapboxAccessToken="pk.eyJ1IjoiZG9tZGlubmVzIiwiYSI6ImNtOHg4MDhxMTAwdHYybHM5NTZ0d3pxNGkifQ.WQMCprVisnV_NUcCOmxyTA"
            initialViewState={{
              longitude: -1.505,
              latitude: 52.09,
              zoom: 10,
            }}
            style={{ width: "100%", height: "100%", borderRadius: "0px" }}
            mapStyle="mapbox://styles/mapbox/streets-v12"
            attributionControl={false}
            onLoad={() => setIsMapLoading(false)}
          >
            <NavigationControl position="top-left" showCompass={false} />

            <Marker latitude={52.09} longitude={-1.505} />
          </Map>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 w-full flex md:hidden justify-end items-center py-3 px-6 bg-white border-t">
        {renderSkipButton()}
      </div>
    </div>
  );
};

export default SelectLocation;
