import { Calendar, Mail, User, X } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { formatDateForInput } from "../../lib/utils";
import { Badge } from "../ui/badge";
import { format } from "date-fns";
import { IAttendee } from "../../types/attendeeTypes";

interface ViewAttendeeDetailsProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  attendee?: IAttendee;
}

const ViewAttendeeDetails: React.FC<ViewAttendeeDetailsProps> = ({
  isOpen,
  setIsOpen,
  attendee,
}) => {

  return (
    isOpen && (
      <div className="fixed inset-0 z-50 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center p-4">
        <div className="bg-white rounded-3xl shadow-xl w-full max-w-md">
          <div className="p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-gray-800">
                Attendee Details
              </h2>
              <button
                onClick={() => setIsOpen(false)}
                className="text-gray-500 hover:text-gray-700 transition-colors"
                aria-label="Close"
              >
                <X size={24} />
              </button>
            </div>

            <div className="flex justify-center items-center mb-2">
              <div className="bg-blue-100 p-4 rounded-full">
                <User className="w-16 h-16 text-blue-500" />
              </div>
            </div>

            <div className="text-center mb-6">
              <h3 className="text-xl font-semibold text-gray-700">{`${attendee?.person?.firstName} ${attendee?.person?.lastName}`}</h3>
              {/* {attendee?.medicalConditions &&
                attendee.medicalConditions.map((condition) => {
                  return (
                    <Badge variant="outline" className="mt-2">
                      {condition}
                    </Badge>
                  );
                })} */}
            </div>

            <Card className="mb-3">
              <CardHeader className="p-4 pb-2">
                <CardTitle className="text-sm">Medical Conditions</CardTitle>
              </CardHeader>
              <CardContent className="p-4 pt-0">
                <div className="flex flex-wrap gap-2">
                  {attendee?.medicalConditions &&
                  attendee?.medicalConditions?.length > 0 ? (
                    attendee.medicalConditions.map((condition, index) => (
                      <Badge
                        key={index}
                        variant="secondary"
                        className="text-xs"
                      >
                        {condition}
                      </Badge>
                    ))
                  ) : (
                    <span className="text-sm text-gray-500">None reported</span>
                  )}
                </div>
              </CardContent>
            </Card>

            <Card className="overflow-hidden text-sm">
              <CardContent className="p-4">
                <div className="flex flex-col gap-4 text-gray-700">
                  <div className="flex items-center gap-3">
                    <User className="h-5 w-5 text-blue-500" />
                    <div>{attendee?.person?.gender}</div>
                  </div>
                  <div className="flex items-center gap-3">
                    <Calendar className="h-5 w-5 text-blue-500" />
                    <div>
                      {format(
                        new Date(
                          formatDateForInput(attendee?.person?.dateOfBirth),
                        ),
                        "dd-MM-yyyy",
                      )}
                    </div>
                  </div>
                  <div className="flex items-center gap-3">
                    <Mail className="h-5 w-5 text-blue-500" />
                    <div>
                      Created by{" "}
                      <span className="font-medium">
                        {attendee?.bookingUser?.email}
                      </span>
                    </div>
                  </div>
                  {/* <div className="flex items-center gap-3">
                    <Clock className="h-5 w-5 text-blue-500" />
                    <div>
                      Created on{" "}
                      {format(
                        new Date(attendee?.createdAt as string),
                        "dd-MM-yyyy"
                      )}
                    </div>
                  </div> */}
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    )
  );
};

export default ViewAttendeeDetails;
