import { z } from "zod";

export const CreateUserSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  gender: z.string().min(1, "Gender is required"),
  dateOfBirth: z
    .string()
    .min(1, "Date of birth is required")
    .refine(
      (dob) => {
        const dobDate = new Date(dob);
        const currentDate = new Date();
        return dobDate <= currentDate;
      },
      {
        message: "Date of birth cannot be in the future",
      }
    ),
  email: z.string().email("Invalid email address"),
  password: z.string().min(6, "Password must be at least 6 characters long"),
});
