import { useQuery } from "react-query";
import { useOrganizationApi } from "./organization.api";

type QueryType = "public" | "admin";

interface StripeStatus {
  hasStripeAccount: boolean;

  isFullySetUp: boolean;

  chargesEnabled: boolean;

  payoutsEnabled: boolean;

  detailsSubmitted: boolean;
}

interface PaymentOption {
  uuid: string;
  name: string;
  enabled: boolean;
}

const KEYS = {
  all: (type: QueryType) => ["activities", type] as const,
  detail: (type: QueryType, id: string) => ["activities", type, id] as const,
  organization: (id: string) => ["organization", id] as const,
  venue: "venue",
  venueById: (id: string) => ["venue", id] as const,
  organizationPaymentOptions: (id: string) =>
    ["organizationPaymentOptions", id] as const,
  discountCodes: ["discount-codes"],
  organizationImage: ["organizationImage"],
  stripeInfo: ["stripeInfo"],
};

export const useGetAllOrganizationImages = (
  id: string | undefined,
  enabled: boolean
) => {
  const organizationApi = useOrganizationApi();
  const admin = organizationApi.admin;

  return useQuery({
    queryKey: KEYS.organizationImage,
    queryFn: () => admin.getAllOrganizationImages({ id: id ?? "" }),
    enabled: enabled,
  });
};

export const useGetOrganizationById = (
  id: string | undefined,
  enabled: boolean
) => {
  const organizationApi = useOrganizationApi();
  const admin = organizationApi.admin;

  return useQuery({
    queryKey: KEYS.organization(id ?? ""),
    queryFn: () => admin.getOrganizationById({ id: id ?? "" }),
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
};

export const useGetAllVenues = (type: QueryType) => {
  const organizationApi = useOrganizationApi();
  const api = type === "admin" ? organizationApi.admin : organizationApi.public;

  return useQuery({
    queryKey: KEYS.venue,
    queryFn: api.getAllVenues,
  });
};

export const useGetVenueById = (id: string | undefined, enabled: boolean) => {
  const organizationApi = useOrganizationApi();
  const admin = organizationApi.admin;

  return useQuery({
    queryKey: KEYS.venueById(id ?? ""),
    queryFn: () => admin.getVenueById(id ?? ""),
    enabled: enabled,
  });
};

export const useGetStripeAccountStatus = () => {
  const organizationApi = useOrganizationApi();
  const admin = organizationApi.admin;

  return useQuery<StripeStatus, Error>(
    "stripeAccountStatus",
    admin.getStripeAccountStatus
  );
};

export const useGetAllDiscounts = () => {
  const organizationApi = useOrganizationApi();
  const admin = organizationApi.admin;

  return useQuery({
    queryKey: KEYS.discountCodes,
    queryFn: admin.getAllDiscounts,
  });
};

export const useGetOrganizationPaymentOptions = (
  id: string | undefined,
  enabled: boolean,
  type: QueryType
) => {
  const organizationApi = useOrganizationApi();
  const api = type === "admin" ? organizationApi.admin : organizationApi.public;
 
  return useQuery<PaymentOption[], Error>({
    queryKey: KEYS.organizationPaymentOptions(id ?? ""),
    queryFn: () => api.getOrganizationPaymentOptions(id ?? ""),
    enabled: enabled,
  });
};


export const useStripeConnectInfo = () => {
  const organizationApi = useOrganizationApi();
  const publicApi = organizationApi.public;

  return useQuery(KEYS.stripeInfo, publicApi.getStripeConnectInfo);
}