import { useQuery } from "react-query";
import {
  ACTIVITY_KEYS,
  PACKAGED_SESSION_KEYS,
  SESSION_KEYS,
  useActivitiesApi,
} from "./activities.api";
import { PackagedSession } from "../../../types/sessionTypes";

type QueryType = "public" | "admin";

export const useActivities = (
  venueUuid?: string | undefined,
  type: QueryType = "admin"
) => {
  const activitiesApi = useActivitiesApi();
  const api = type === "public" ? activitiesApi.public : activitiesApi.admin;

  return useQuery({
    queryKey: ACTIVITY_KEYS.all(),
    queryFn: venueUuid ? () => api.getAll(venueUuid) : () => api.getAll(),
  });
};

export const useActivity = (
  id: string,
  enable: boolean,
  type: QueryType = "admin"
) => {
  const activitiesApi = useActivitiesApi();
  const api = type === "public" ? activitiesApi.public : activitiesApi.admin;

  return useQuery({
    queryKey: ACTIVITY_KEYS.detail(type, id),
    queryFn: () => api.getById(id),
    enabled: enable,
  });
};

export const useSessionsForActivity = (
  uuid: string,
  enable: boolean,
  type: QueryType = "admin"
) => {
  const activitiesApi = useActivitiesApi();
  const api = type === "public" ? activitiesApi.public : activitiesApi.admin;

  return useQuery({
    queryKey: SESSION_KEYS.detail("admin", uuid),
    queryFn: () => api.getSessionsForActivity(uuid),
    enabled: enable,
  });
};

export const useGetPackagedSessionsForActivity = (
  uuid: string,
  enable: boolean
) => {
  const activitiesApi = useActivitiesApi();
  const api = activitiesApi.public;

  return useQuery({
    queryKey: PACKAGED_SESSION_KEYS.detail("admin", uuid),
    queryFn: () => api.getPackagedSessionsForActivity(uuid),
    enabled: enable,
  });
};

export const useSessions = () => {
  const activitiesApi = useActivitiesApi();
  const api = activitiesApi.admin;

  return useQuery({
    queryKey: SESSION_KEYS.all(),
    queryFn: api.getAllSessions,
  });
};

export const useSession = (id: string, enable: boolean) => {
  const activitiesApi = useActivitiesApi();
  const api = activitiesApi.admin;

  return useQuery({
    queryKey: SESSION_KEYS.detail("admin", id),
    queryFn: () => api.getSessionById(id),
    enabled: enable,
  });
};

export const useGetAllPackagedSessions = () => {
  const activitiesApi = useActivitiesApi();
  const api = activitiesApi.admin;

  return useQuery({
    queryKey: PACKAGED_SESSION_KEYS.all(),
    queryFn: api.getAllPackagedSessions,
  });
};

export const useGetAllPackagedSessionByActivity = (activityUuid: string) => {
  const activitiesApi = useActivitiesApi();
  const api = activitiesApi.admin;

  return useQuery({
    queryKey: PACKAGED_SESSION_KEYS.allByActivity(activityUuid),
    queryFn: () => api.getAllPackagedSessionByActivity(activityUuid),
  });
};

export const useGetPackagedSessionById = (id: string, isEnabled = true) => {
  const activitiesApi = useActivitiesApi();
  const api = activitiesApi.admin;

  return useQuery<PackagedSession>({
    queryKey: PACKAGED_SESSION_KEYS.detail("admin", id),
    queryFn: () => api.getPackagedSessionById(id),
    enabled: isEnabled,
  });
};
