import { API } from "../../../config/api";
import { useAxiosPrivate } from "../axios/private";
import useAxiosPublic from "../axios/public";

interface UpdateOrganizationData {
  uuid: string;
  data: {
    name: string;
    heading: string;
    description: string;
    customerEmail: string | null | undefined;
    currency: string;
    active: boolean;
    imageUrl: string | null | undefined;
    activityDiscoveryType: "DEFAULT" | "BY_LOCATION";
  };
}

interface CreateSocialMediaLinkData {
  orgId: string;
  data: {
    socialMediaType: string;
    link: string;
  };
}

interface DeleteSocialMediaLinkData {
  orgId: string;
  data: {
    data: {
      socialMediaType: string;
    };
  };
}

interface ICreateVenue {
  name: string;
  line2?: string;
  townCity?: string;
  line1: string;
  postCode: string;
  country: string;
}

interface UpdateVenueData {
  name: string;
  line2?: string;
  townCity?: string;
  line1: string;
  postCode: string;
  country: string;
}

export const useOrganizationApi = () => {
  const privateInstance = useAxiosPrivate();
  const publicInstance = useAxiosPublic();

  return {
    public: {
      getAll: async () => {
        const response = await publicInstance.get(API.GET_ACTIVITIES);
        return response.data;
      },
      getById: async (id: string) => {
        const response = await publicInstance.get(
          `${API.GET_ACTIVITIES}/${id}`
        );
        return response.data;
      },
      getStripeConnectInfo: async () => {
        const response = await publicInstance.get("/stripe-connect-info");
        return response.data;
      },
      getAllVenues: async () => {
        const response = await publicInstance.get(API.VENUE);
        return response.data;
      },
      getOrganizationPaymentOptions: async (organizationUuuid: string) => {
        const response = await publicInstance.get(
          `${API.ORGANIZATION}/${organizationUuuid}/payment-option`
        );
        return response.data;
      },
    },
    admin: {
      getAllOrganizationImages: async ({ id }: { id: string }) => {
        const response = await privateInstance.get(
          `${API.ORGANIZATION}/${id}/image/metadata`
        );
        return response.data;
      },
      updateOrganizationById: async (data: UpdateOrganizationData) => {
        const response = await privateInstance.put(
          `${API.ADMIN_ORGANIZATION}/${data.uuid}`,
          data.data
        );
        return response.data;
      },
      getOrganizationById: async ({ id }: { id: string }) => {
        const response = await privateInstance.get(
          `${API.ADMIN_ORGANIZATION}/${id}`
        );
        return response.data;
      },
      createSocialMediaLink: async (data: CreateSocialMediaLinkData) => {
        const response = await privateInstance.post(
          API.SOCIAL_LINK + data.orgId,
          data.data
        );
        return response.data;
      },
      updateSocialMediaLink: async (data: CreateSocialMediaLinkData) => {
        const response = await privateInstance.put(
          API.SOCIAL_LINK + data.orgId,
          data.data
        );
        return response.data;
      },
      deleteSocialMediaLink: async (data: DeleteSocialMediaLinkData) => {
        const response = await privateInstance.delete(
          API.SOCIAL_LINK + data.orgId,
          data.data
        );
        return response.data;
      },
      getAllVenues: async () => {
        const response = await privateInstance.get(API.VENUE);
        return response.data;
      },
      getVenueById: async (id: string) => {
        const response = await privateInstance.get(`${API.VENUE}/${id}`);
        return response.data;
      },
      createVenue: async ({ ...data }: ICreateVenue) => {
        const response = await privateInstance.post(API.VENUE, data);
        return response.data;
      },
      updateVenue: async ({
        data,
        uuid,
      }: {
        data: UpdateVenueData;
        uuid: string;
      }) => {
        const response = await privateInstance.put(
          API.VENUE + `/${uuid}`,
          data
        );
        return response.data;
      },
      createStripeAccountLinkUrl: async () => {
        const response = await privateInstance.post(`/stripe-connect`);
        return response.data;
      },
      getStripeAccountStatus: async () => {
        const response = await privateInstance.get("/stripe-status");
        return response.data;
      },
      initiateStripePayment: async ({
        bookingTxnId,
      }: {
        bookingTxnId: string;
      }) => {
        const response = await privateInstance.post(
          `${API.BOOKING_TXN}/${bookingTxnId}/initiate`
        );
        return response.data;
      },
      createDiscountCode: async ({
        code,
        discountType,
        discountValue,
        validFrom,
        description,
        maxUses,
        validUntil,
        isActive,
      }: {
        code: string;
        discountType: string;
        discountValue: number;
        validFrom: string;
        description?: string;
        maxUses?: number;
        validUntil?: string;
        isActive: boolean;
      }) => {
        const response = await privateInstance.post(API.DISCOUNT_CODE, {
          code,
          discountType,
          discountValue,
          validFrom,
          description,
          maxUses,
          validUntil,
          isActive,
        });
        return response.data;
      },
      editDiscountCode: async ({
        code,
        discountType,
        discountValue,
        validFrom,
        description,
        maxUses,
        validUntil,
        isActive,
        discountUUID,
      }: {
        code: string;
        discountType: string;
        discountValue: number;
        validFrom: string;
        description?: string;
        maxUses?: number;
        validUntil?: string;
        isActive: boolean;
        discountUUID: string;
      }) => {
        const response = await privateInstance.put(
          API.DISCOUNT_CODE + "/" + discountUUID,
          {
            code,
            discountType,
            discountValue,
            validFrom,
            description,
            maxUses,
            validUntil,
            isActive,
          }
        );
        return response.data;
      },
      getAllDiscounts: async () => {
        const response = await privateInstance.get(API.DISCOUNT_CODE);
        return response.data;
      },
      getOrganizationPaymentOptions: async (organizationUuuid: string) => {
        const response = await privateInstance.get(
          `${API.ORGANIZATION}/${organizationUuuid}/payment-option`
        );
        return response.data;
      },
      updateOrganizationPaymentOptions: async (
        enabled: boolean,
        option: string
      ) => {
        const response = await privateInstance.put(`/payment-option`, {
          enabled,
          option,
        });
        return response.data;
      },
    },
  };
};
