import { useQuery, UseQueryResult } from "react-query";
import { useUserApi } from "./user.api";
import { BookingUserType, StaffType } from "../../../types/staffTypes";
import { IAttendee } from "../../../types/attendeeTypes";

interface DateOfBirth {
  _day: number;
  _month: number;
  _year: number;
}

interface Person {
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: DateOfBirth;
}

interface CurrentUser {
  uid: string;
  person: Person;
  email: string;
  createdAt: string;
}

export const USER_KEYS = {
  staff: ["staff"],
  staffId: (id: string) => ["staff", id],
  user: ["user"],
  currentBookingUser: (id: string) => ["current-booking-user", id],
  userAttendees: (id?: string) => ["user-attendees", id],
  attendee: (id?: string) => ["attendee", id],
  singleAttendee: () => ["attendee"],
};

export const useGetStaff = (staleTime?: number) => {
  const registerApi = useUserApi();
  const api = registerApi.admin;

  return useQuery<StaffType[] | undefined>({
    queryKey: USER_KEYS.staff,
    queryFn: api.getStaff,
    staleTime: staleTime,
  });
};

export const useGetBookingUsers = (staleTime?: number) => {
  const registerApi = useUserApi();
  const api = registerApi.admin;

  return useQuery<BookingUserType[]>({
    queryKey: USER_KEYS.user,
    queryFn: api.getAllBookingUsers,
    staleTime: staleTime,
  });
};

export const useGetStaffUserById = (
  id: string,
  enabled: boolean
): UseQueryResult<StaffType, unknown> => {
  const registerApi = useUserApi();
  const api = registerApi.admin;

  return useQuery({
    queryKey: USER_KEYS.staffId(id),
    queryFn: () => api.getStaffUserById(id),
    enabled,
  });
};

export const useGetBookingUserById = (id: string | undefined) => {
  const registerApi = useUserApi();
  const api = registerApi.admin;

  return useQuery<CurrentUser>({
    queryKey: USER_KEYS.currentBookingUser(id ?? ""),
    queryFn: () => api.getBookingUserById(id as string),
    enabled: !!id,
  });
};

export const useGetAttendees = (id: string | undefined) => {
  const registerApi = useUserApi();
  const adminApi = registerApi.admin;
  const publicApi = registerApi.public;

  return useQuery({
    queryKey: USER_KEYS.attendee(id),
    queryFn: id
      ? () => adminApi.getAttendeesByUserId(id as string)
      : () => publicApi.getAllAttendees(),
    enabled: !!id,
  });
};

export const useGetAttendeesPublic = (enabled: boolean = true) => {
  const registerApi = useUserApi();
  const publicApi = registerApi.public;

  return useQuery<IAttendee[], Error>({
    queryKey: USER_KEYS.singleAttendee(),
    queryFn: publicApi.getAllAttendees,
    enabled: enabled,
  });
};

export const useGetLoggedInUser = () => {
  const registerApi = useUserApi();
  const adminApi = registerApi.admin;

  return useQuery({
    queryFn: () => adminApi.getLoggedInUser(),
  });
};

export const useGetAttendee = () => {
  const registerApi = useUserApi();
  const adminApi = registerApi.admin;

  return useQuery({
    queryKey: USER_KEYS.singleAttendee(),
    queryFn: () => adminApi.getAttendee(),
  });
};
