import React, { useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { z } from "zod";

import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Switch } from "../ui/switch";
import CreatableSelect from "react-select/creatable";

import {
  Select as SelectShadCn,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { CloseIcon } from "@/assets/Icons/icon";
import AttendeeDisplay from "./AttendeeDisplay";

import { CreateAttendeeSchema } from "@/constants/validations/attendee";
import { IAttendee } from "@/types/attendeeTypes";
import { format } from "date-fns";
import { UtcToLocal } from "../../lib/utils";
import { SelectOption } from "../../types/selectType";

import { ChevronDown, ChevronUp, Info, PlusCircle, X } from "lucide-react";

import { Button } from "../ui/button";
import { StylesConfig } from "react-select";

import { useGetAttendees } from "../../core/api/user/user.queries";
import {
  useCreateAttendee,
  useCreateAttendeeForUser,
  useUpdateAttendee,
  useUpdateAttendeeForUser,
} from "../../core/api/user/user.mutation";

const allergyOptions: SelectOption[] = [
  { value: "peanut_allergy", label: "Peanut Allergy" },
  { value: "lactose_intolerance", label: "Lactose Intolerance" },
  { value: "gluten_allergy", label: "Gluten Allergy" },
  { value: "egg_allergy", label: "Egg Allergy" },
  { value: "soy_allergy", label: "Soy Allergy" },
];

const disabilityOptions: SelectOption[] = [
  { value: "blindness", label: "Blindness" },
  { value: "deafness", label: "Deafness" },
  { value: "dyslexia", label: "Dyslexia" },
  { value: "paralysis", label: "Paralysis" },
  { value: "autism", label: "Autism" },
  { value: "adhd", label: "ADHD" },
];

type FormData = z.infer<typeof CreateAttendeeSchema> & {
  hasAllergies: boolean;
  hasDisability: boolean;
  photoConsent: boolean;
  firstAidConsent: boolean;
};

interface CreateEditAttendeeProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  userUuid?: string;
  isEdit?: boolean;
  attendeeToEdit?: IAttendee;
}

const CreateAttendee: React.FC<CreateEditAttendeeProps> = ({
  isOpen,
  onClose,
  onSuccess,
  userUuid,
  isEdit = false,
  attendeeToEdit,
}) => {
  const [showInfo, setShowInfo] = useState(false);

  const [newAllergyEntry, setNewAllergyEntry] = useState("");
  const [newDisabilityEntry, setNewDisabilityEntry] = useState("");
  const [isAddingNewAllergy, setIsAddingNewAllergy] = useState(false);
  const [isAddingNewDisability, setIsAddingNewDisability] = useState(false);

  const [hasAllergies, setHasAllergies] = useState<boolean>(false);
  const [hasDisability, setHasDisability] = useState<boolean>(false);
  const [photoConsent, setPhotoConsent] = useState<boolean>(false);
  const [firstAidConsent, setFirstAidConsent] = useState<boolean>(false);
  // const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);
  const [selectedAllergies, setSelectedAllergies] = useState<SelectOption[]>(
    []
  );
  const [selectedDisabilities, setSelectedDisabilities] = useState<
    SelectOption[]
  >([]);


  const { mutateAsync: createAttendeeForUser } = useCreateAttendeeForUser();
  const { mutateAsync: updateAttendeeForUser } = useUpdateAttendeeForUser();
  const { mutateAsync: createAttendee } = useCreateAttendee();
  const { mutateAsync: updateAttendee } = useUpdateAttendee();

  const customStyles = useMemo(
    (): StylesConfig<SelectOption, true> => ({
      control: (base) => ({
        ...base,
        minHeight: "38px",
        borderRadius: "20px",
        boxShadow: "none",
        borderColor: "#C5D6EB",
        "&:hover": {
          borderColor: "#3C66FA",
        },
      }),
      placeholder: (base) => ({
        ...base,
        fontSize: "0.85rem",
        color: "#9CA3AF",
      }),
      valueContainer: (base) => ({
        ...base,
        padding: "0 4px",
      }),
      input: (base) => ({
        ...base,
        margin: "0",
        padding: "0",
      }),
      indicatorsContainer: (base) => ({
        ...base,
        height: "30px",
      }),
      dropdownIndicator: (base) => ({
        ...base,
        padding: "4px",
        paddingTop: "5px",
      }),
      clearIndicator: (base) => ({
        ...base,
        padding: "4px",
        paddingTop: "5px",
      }),
      multiValue: (base) => ({
        ...base,
        backgroundColor: "#E5E7EB",
      }),
      multiValueLabel: (base) => ({
        ...base,
        fontSize: "0.75rem",
        padding: "1px 2px",
      }),
      multiValueRemove: (base) => ({
        ...base,
        padding: "0 2px",
        "&:hover": {
          backgroundColor: "#D1D5DB",
          color: "#1F2937",
        },
      }),
      menu: (base) => ({
        ...base,
        zIndex: 9999,
      }),
      menuList: (base) => ({
        ...base,
        maxHeight: "120px",
      }),
      option: (base, state) => ({
        ...base,
        fontSize: "0.8rem",
        padding: "2px 6px",
        backgroundColor: state.isSelected
          ? "#3C66FA"
          : state.isFocused
            ? "#E5E7EB"
            : "white",
        color: state.isSelected ? "white" : "#1F2937",
        "&:active": {
          backgroundColor: "#3C66FA",
          color: "white",
        },
      }),
    }),
    []
  );

  const formatOptionLabel = ({ label }: SelectOption) => label;

  const {
    register,
    handleSubmit,
    control,
    setValue,

    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(CreateAttendeeSchema),
    defaultValues: {
      medicalConditions: [],
      hasAllergies: false,
      hasDisability: false,
      photoConsent: false,
      firstAidConsent: false,
    },
  });

  const sortOptions = (options: SelectOption[]) => {
    return [...options].sort((a, b) => a.label.localeCompare(b.label));
  };

  const sortedAllergyOptions = useMemo(() => sortOptions(allergyOptions), []);
  const sortedDisabilityOptions = useMemo(
    () => sortOptions(disabilityOptions),
    []
  );

  const handleAllergyChange = (newValue: readonly SelectOption[]) => {
    const sortedNewValue = sortOptions(newValue as SelectOption[]);
    setSelectedAllergies(sortedNewValue);
    updateMedicalConditions(sortedNewValue, selectedDisabilities);
  };

  const handleDisabilityChange = (newValue: readonly SelectOption[]) => {
    const sortedNewValue = sortOptions(newValue as SelectOption[]);
    setSelectedDisabilities(sortedNewValue);
    updateMedicalConditions(selectedAllergies, sortedNewValue);
  };

  const updateMedicalConditions = (
    allergies: SelectOption[],
    disabilities: SelectOption[]
  ) => {
    const conditions = [...allergies, ...disabilities].map(
      (option) => option.label
    );
    setValue("medicalConditions", conditions);
  };

  const { data: attendeeData, refetch: refetchAttendees } =
    useGetAttendees(userUuid);

  useEffect(() => {
    if (isEdit && attendeeToEdit) {
      setValue("firstName", attendeeToEdit.person.firstName);
      setValue("lastName", attendeeToEdit.person.lastName);
      setValue("gender", attendeeToEdit.person.gender);
      const { _year, _month, _day } = attendeeToEdit.person.dateOfBirth;
      const attendeeDob = UtcToLocal(new Date(_year, _month, _day), true);
      setValue("dateOfBirth", format(attendeeDob as Date, "yyyy-MM-dd"));
      setValue("medicalConditions", attendeeToEdit.medicalConditions);

      const allergyConditions = attendeeToEdit.medicalConditions.filter(
        (condition) =>
          allergyOptions.some((option) => option.label === condition)
      );
      const disabilityConditions = attendeeToEdit.medicalConditions.filter(
        (condition) =>
          disabilityOptions.some((option) => option.label === condition)
      );
      setSelectedAllergies(
        allergyConditions.map((condition) => ({
          label: condition,
          value: condition,
        }))
      );
      setSelectedDisabilities(
        disabilityConditions.map((condition) => ({
          label: condition,
          value: condition,
        }))
      );
      setHasAllergies(allergyConditions.length > 0);
      setHasDisability(disabilityConditions.length > 0);
    }
  }, [isEdit, attendeeToEdit, setValue, isOpen]);

  const onSubmit = async (data: FormData) => {
    try {
  

      if (userUuid) {
  
        if (isEdit) {
          // Admin edit mutations
    
          updateAttendeeForUser({
            userUuid: userUuid,
            attendeeUuid: attendeeToEdit?.uid || "",
            person: {
              firstName: data.firstName,
              lastName: data.lastName,
              dateOfBirth: data.dateOfBirth,
              gender: data.gender,
            },
            medicalConditions: data.medicalConditions,
          }, {
            onSuccess: () => {
              refetchAttendees();
              onSuccess();
              reset();
            }
          });
        } else {
          // Admin create mutations
          createAttendeeForUser({
            userUuid: userUuid,
            person: {
              firstName: data.firstName,
              lastName: data.lastName,
              dateOfBirth: data.dateOfBirth,
              gender: data.gender,
            },
            medicalConditions: data.medicalConditions,
          }, {
            onSuccess: () => {
              refetchAttendees();
              onSuccess();
              reset();
            }
          })
        }
      } else {
        if (isEdit) {

          updateAttendee({
            attendeeUuid: attendeeToEdit?.uid || "",
            person: {
              firstName: data.firstName,
              lastName: data.lastName,
              dateOfBirth: data.dateOfBirth,
              gender: data.gender,
            },
            medicalConditions: data.medicalConditions,
          }, {
            onSuccess: () => {
              refetchAttendees();
              onSuccess();
              reset();
            }
          });
        } else {
          // Public create mutations

          createAttendee({
            person: {
              firstName: data.firstName,
              lastName: data.lastName,
              dateOfBirth: data.dateOfBirth,
              gender: data.gender,
            },
            medicalConditions: data.medicalConditions,
          }, {
            onSuccess: () => {
              refetchAttendees();
              onSuccess();
              reset();
            }
          });
        }
        // Public mutations
      }
      // await createOrUpdateAttendeeAsync({
      //   person: data,
      //   userUuid: userUuid || "",
      //   attendeeUuid: isEdit ? attendeeToEdit?.uid : undefined,
      // });
    } catch (error) {
      console.error(`Error ${isEdit ? "updating" : "submitting"} form:`, error);
    }
  };

  if (!isOpen) return null;

  const CustomSwitch: React.FC<{
    id: string;
    checked: boolean;
    onCheckedChange: (checked: boolean) => void;
    label: string;
  }> = ({ id, checked, onCheckedChange, label }) => (
    <div className="flex items-center space-x-2">
      <Switch
        id={id}
        checked={checked}
        onCheckedChange={onCheckedChange}
        className={`${checked ? "bg-blue-600" : "bg-gray-200"
          } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2`}
      >
        <span
          className={`${checked ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
        />
      </Switch>
      <Label htmlFor={id} className="text-sm font-medium text-gray-900">
        {label}
      </Label>
    </div>
  );

  const handleAddNewEntry = (type: "allergy" | "disability") => {
    const newEntry = type === "allergy" ? newAllergyEntry : newDisabilityEntry;
    if (newEntry.trim() === "") return;

    const newOption = { value: newEntry, label: newEntry };
    if (type === "allergy") {
      setSelectedAllergies(sortOptions([...selectedAllergies, newOption]));
      setNewAllergyEntry("");
      setIsAddingNewAllergy(false);
    } else {
      setSelectedDisabilities(
        sortOptions([...selectedDisabilities, newOption])
      );
      setNewDisabilityEntry("");
      setIsAddingNewDisability(false);
    }
    updateMedicalConditions(
      type === "allergy"
        ? sortOptions([...selectedAllergies, newOption])
        : selectedAllergies,
      type === "disability"
        ? sortOptions([...selectedDisabilities, newOption])
        : selectedDisabilities
    );
  };

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center p-4">
      <div className="bg-white rounded-3xl shadow-xl max-w-md w-full space-y-4 overflow-y-auto max-h-[90vh]">
        <div className="flex items-center justify-between px-5 pt-6 sticky top-0 bg-white z-10">
          <div className="flex items-center gap-2">
            <h2 className="text-xl sm:text-2xl font-semibold">
              {isEdit ? "Edit" : "Create"} Attendee
            </h2>
            <button
              onClick={() => setShowInfo(!showInfo)}
              className="text-xs text-gray-500 hover:text-gray-700 flex items-center"
            >
              <Info className="h-3 w-3 mr-1" />
              <span className="hidden sm:inline">
                {showInfo ? "Hide info" : "What's this?"}
              </span>
              {showInfo ? (
                <ChevronUp className="h-3 w-3 ml-1" />
              ) : (
                <ChevronDown className="h-3 w-3 ml-1" />
              )}
            </button>
          </div>
          <button
            onClick={() => {
              onClose();
              setShowInfo(false);
              setSelectedAllergies([]);
              setSelectedDisabilities([]);

              setHasAllergies(false);
              setHasDisability(false);

              reset();
            }}
            className="text-gray-500 hover:text-gray-700 transition-colors"
          >
            <CloseIcon size={24} />
          </button>
        </div>
        <div className="px-6 pb-6 space-y-4">
          {showInfo && (
            <div className="bg-blue-50 border-l-4 border-blue-500 p-3 text-sm">
              <p>
                Creating attendees allows you to manage bookings for yourself,
                family members, or others. You can assign these attendees to
                different sessions you book.
              </p>
            </div>
          )}

          {!isEdit && attendeeData && attendeeData.length > 0 && (
            <AttendeeDisplay attendees={attendeeData} />
          )}

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="firstName">First Name*</Label>
              <Input
                id="firstName"
                placeholder="Alex"
                {...register("firstName")}
                className="border border-[#C5D6EB] focus:bg-white  focus:border-[#3C66FA] focus:border rounded-3xl py-5"
              />
              {errors?.firstName && (
                <p className="text-xs text-red-600">
                  {errors.firstName.message}
                </p>
              )}
            </div>
            <div className="space-y-2">
              <Label htmlFor="lastName">Last Name*</Label>
              <Input
                id="lastName"
                placeholder="Mill"
                {...register("lastName")}
                className="border border-[#C5D6EB] focus:bg-white  focus:border-[#3C66FA] focus:border rounded-3xl py-5"
              />
              {errors?.lastName && (
                <p className="text-xs text-red-600">
                  {errors.lastName.message}
                </p>
              )}
            </div>
            <div className="flex gap-4">
              <div className="flex-1 space-y-2">
                <Label htmlFor="gender">Gender*</Label>
                <Controller
                  name="gender"
                  control={control}
                  render={({ field }) => (
                    <SelectShadCn
                      onValueChange={field.onChange}
                      value={field.value}
                    >
                      <SelectTrigger
                        id="selectGender"
                        className="border border-[#C5D6EB] focus:bg-white  focus:border-[#3C66FA] focus:border rounded-3xl py-5"
                      >
                        <SelectValue placeholder="Select Gender" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem id="selectGenderItem" value="MALE">
                          Male
                        </SelectItem>
                        <SelectItem id="selectGenderItem" value="FEMALE">
                          Female
                        </SelectItem>
                        <SelectItem id="selectGenderItem" value="OTHER">
                          Other
                        </SelectItem>
                      </SelectContent>
                    </SelectShadCn>
                  )}
                />
                {errors?.gender && (
                  <p className="text-xs text-red-600">
                    {errors.gender.message}
                  </p>
                )}
              </div>
              <div className="flex-1 gap-2 flex flex-col space-y-2">
                <Label htmlFor="dob">Date of Birth*</Label>
                <input
                  id="dob"
                  placeholder="yyyy-mm-dd"
                  type="date"
                  autoCapitalize="none"
                  className="border border-[#C5D6EB] w-full  text-[#182143] text-sm  focus:border-[#3C66FA] focus:border rounded-3xl py-2 px-3"
                  autoComplete="text"
                  max={new Date().toISOString().split("T")[0]}
                  autoCorrect="off"
                  {...register("dateOfBirth")}
                />
                {errors?.dateOfBirth && (
                  <p className="text-xs text-red-600">
                    {errors.dateOfBirth.message}
                  </p>
                )}
              </div>
            </div>

            <div className="flex items-center space-x-2">
              <Controller
                name="hasAllergies"
                control={control}
                render={({ field }) => (
                  <CustomSwitch
                    id="has-allergies"
                    checked={hasAllergies}
                    onCheckedChange={(checked) => {
                      setHasAllergies(checked);
                      field.onChange(checked);
                      if (!checked) {
                        setSelectedAllergies([]);
                        updateMedicalConditions([], selectedDisabilities);
                      }
                    }}
                    label="My attendee has allergies"
                  />
                )}
              />
            </div>

            {hasAllergies && (
              <div className="space-y-2">
                <div className="flex justify-between items-center">
                  <Label htmlFor="allergies">Allergies</Label>
                  {!isAddingNewAllergy && (
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => setIsAddingNewAllergy(true)}
                      className="flex items-center"
                    >
                      <PlusCircle className="mr-2 h-4 w-4" />
                      Add New Allergy
                    </Button>
                  )}
                </div>
                <CreatableSelect
                  isMulti
                  id="allergies"
                  styles={customStyles}
                  value={selectedAllergies}
                  onChange={(newValue: readonly SelectOption[]) =>
                    handleAllergyChange(newValue)
                  }
                  options={sortedAllergyOptions}
                  placeholder="Select or type to add..."
                  noOptionsMessage={() => "Type to add a new allergy"}
                  formatOptionLabel={formatOptionLabel}
                  className="rounded-3xl"
                />
                {isAddingNewAllergy && (
                  <div className="flex items-center space-x-2 mt-2">
                    <Input
                      value={newAllergyEntry}
                      onChange={(e) => setNewAllergyEntry(e.target.value)}
                      placeholder="Enter new allergy"
                      className="flex-grow rounded-3xl"
                    />
                    <Button
                      onClick={() => handleAddNewEntry("allergy")}
                      size="sm"
                    >
                      Add
                    </Button>
                    <Button
                      onClick={() => setIsAddingNewAllergy(false)}
                      size="sm"
                      variant="outline"
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  </div>
                )}
              </div>
            )}

            <div className="flex items-center space-x-2">
              <Controller
                name="hasDisability"
                control={control}
                render={({ field }) => (
                  <CustomSwitch
                    id="has-disability"
                    checked={hasDisability}
                    onCheckedChange={(checked) => {
                      setHasDisability(checked);
                      field.onChange(checked);
                      if (!checked) {
                        setSelectedDisabilities([]);
                        updateMedicalConditions(selectedAllergies, []);
                      }
                    }}
                    label="My attendee has a disability"
                  />
                )}
              />
            </div>
            {hasDisability && (
              <div className="space-y-2">
                <div className="flex justify-between items-center">
                  <Label htmlFor="disabilities">Disabilities</Label>
                  {!isAddingNewDisability && (
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => setIsAddingNewDisability(true)}
                      className="flex items-center"
                    >
                      <PlusCircle className="mr-2 h-4 w-4" />
                      Add New Disability
                    </Button>
                  )}
                </div>
                <CreatableSelect
                  isMulti
                  id="disabilities"
                  styles={customStyles}
                  value={selectedDisabilities}
                  onChange={(newValue: readonly SelectOption[]) =>
                    handleDisabilityChange(newValue)
                  }
                  options={sortedDisabilityOptions}
                  placeholder="Select or type to add..."
                  noOptionsMessage={() => "Type to add a new disability"}
                  formatOptionLabel={formatOptionLabel}
                  className="rounded-3xl"
                />
                {isAddingNewDisability && (
                  <div className="flex items-center space-x-2 mt-2">
                    <Input
                      value={newDisabilityEntry}
                      onChange={(e) => setNewDisabilityEntry(e.target.value)}
                      placeholder="Enter new disability"
                      className="flex-grow rounded-3xl"
                    />
                    <Button
                      onClick={() => handleAddNewEntry("disability")}
                      size="sm"
                    >
                      Add
                    </Button>
                    <Button
                      onClick={() => setIsAddingNewDisability(false)}
                      size="sm"
                      variant="outline"
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  </div>
                )}
              </div>
            )}

            <div className="flex items-center space-x-2">
              <Controller
                name="photoConsent"
                control={control}
                render={({ field }) => (
                  <CustomSwitch
                    id="photo-consent"
                    checked={photoConsent}
                    onCheckedChange={(checked) => {
                      setPhotoConsent(checked);
                      field.onChange(checked);
                    }}
                    label="My attendee consents to having their photo taken"
                  />
                )}
              />
            </div>

            <div className="flex items-center space-x-2">
              <Controller
                name="firstAidConsent"
                control={control}
                render={({ field }) => (
                  <CustomSwitch
                    id="first-aid-consent"
                    checked={firstAidConsent}
                    onCheckedChange={(checked) => {
                      setFirstAidConsent(checked);
                      field.onChange(checked);
                    }}
                    label="My attendee consents to receiving first aid in case of injury or accident"
                  />
                )}
              />
            </div>

            <div className="pt-4">
              <button
                data-testid={`${isEdit ? "edit" : "create"}-attendee-submit`}
                type="submit"
                className={`w-full py-2 px-4 rounded-xl text-white transition-colors bg-primary_colour hover:bg-hover_primary`}
              >
                {isEdit ? "Update" : "Create"} Attendee
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateAttendee;
