import { ArrowLeft } from "lucide-react";
import { useActivities } from "../../../core/api/activities";
import { PublicActivityType } from "../../../types/publicActivityType";
import { getImageLink } from "../../../utils/helper";
import PublicActivitiesStepper from "../PublicActivitiesStepper";
import { useNavigate, useSearchParams } from "react-router-dom";
import { organizationAtom } from "../../../atom/atom";
import { useAtom } from "jotai";
import { getCurrencySymbol } from "../../../lib/utils";
import { Skeleton } from "../../TableSkeleton/Skeleton";

const SelectActivity = () => {
  const navigate = useNavigate();

  const [organization] = useAtom(organizationAtom);

  const { data: activityData, isLoading: isActivityDataLoading } =
    useActivities(undefined, "public");

  const [searchParams] = useSearchParams();
  const venueUuid = searchParams.get("id");

  function handleGoBack() {
    navigate(-1);
  }

  function handleActivitySelect(activityUuid: string) {
    navigate(`/activity/${activityUuid}`);
  }

  return (
    <div className={`flex flex-col w-full`}>
      <div className="flex w-full justify-between px-4 sm:px-6 pt-4 pb-4">
        <div className="flex items-center rounded-md justify-between w-[100%]">
          <div className="flex items-center lg:gap-3 gap-2">
            <button
              onClick={handleGoBack}
              className="h-8 w-8 flex items-center justify-center rounded-full hover:bg-[#3552de]  bg-primary_colour"
            >
              <ArrowLeft className="text-white" />
            </button>
            <h2 className="text-[#182143] lg:text-xl text-lg">
              <span className="inline-block md:hidden">Select Activity</span>
              <span className="hidden md:inline-block">Select Activity</span>
            </h2>
          </div>
        </div>
        <PublicActivitiesStepper currentStep={3} complete={false} />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-3 px-8 md:px-6">
        {isActivityDataLoading && (
          <>
            {[1, 2, 3].map(() => (
              <div className="flex flex-col gap-4 items-center">
                <Skeleton className="h-48 md:h-72 w-full rounded-t-xl md:rounded-xl" />
                <div className="flex flex-col gap-3 w-full">
                  <Skeleton className="h-6 w-1/2" />
                  <Skeleton className="h-6 w-full" />
                </div>
              </div>
            ))}
          </>
        )}
        {!isActivityDataLoading &&
          activityData &&
          (activityData as PublicActivityType[])
            .filter((activity) =>
              venueUuid !== "all"
                ? activity.locations
                    .map((location) => location.uuid.value)
                    .includes(venueUuid as string)
                : true
            )
            .map((activity) => (
              <div
                key={activity.uuid.value}
                onClick={() => handleActivitySelect(activity?.uuid.value)}
                className="font-light flex flex-col gap-3 items-center bg-white rounded-xl cursor-pointer shadow-md hover:shadow-md transition-shadow md:shadow-none md:hover:shadow-none"
              >
                <div className="h-48 md:h-72 w-full rounded-t-xl md:rounded-xl overflow-hidden">
                  <img
                    src={
                      activity?.imageUrl
                        ? getImageLink(activity?.imageUrl)
                        : "/fallback_activity.png"
                    }
                    loading="lazy"
                    alt={activity?.name || "Activity image"}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="flex flex-col w-full justify-start p-3">
                  <div className="font-medium text-gray-800">
                    {activity?.name}
                  </div>
                  <div className="text-sm font-light text-gray-500">
                    From{" "}
                    {organization?.currency
                      ? getCurrencySymbol(organization?.currency as string)
                      : "£"}
                    {activity?.prices?.min.amount} per attendee
                  </div>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default SelectActivity;
