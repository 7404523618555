import * as z from "zod";

export const CreateAttendeeSchema = z.object({
  firstName: z.string().min(1, "First Name is required"),
  lastName: z.string().min(1, "First Name is required"),
  gender: z.string(),
  dateOfBirth: z
    .string()
    .min(1, "Date of Birth is required")
    .refine(
      (dob) => {
        const dobDate = new Date(dob);
        const currentDate = new Date();
        return dobDate <= currentDate;
      },
      {
        message: "Date of birth cannot be in the future",
      }
    ),
  medicalConditions: z.array(z.string()),
});
