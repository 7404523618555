import { ColumnDef } from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { EllipsisHorizontalIcon } from "@/assets/Icons/icon";
import { IAttendee } from "@/types/attendeeTypes";
import { formattedBirthday } from "@/utils/helper";
import { useState } from "react";
import CreateAttendee from "./CreateAttendee";
import { UtcToLocal } from "../../lib/utils";
import { format } from "date-fns";
import ViewAttendeeDetails from "./ViewAttendeeDetails";

import { QueryObserverResult } from "react-query";
import { Eye } from "lucide-react";

export const getColumnsAttendees = (
  refetchAttendees?: () => Promise<QueryObserverResult<IAttendee[], Error>>,
  userUuid?: string,
): ColumnDef<IAttendee>[] => {
  return [
    {
      accessorKey: "name",
      header: "Attendee Name",
      accessorFn: (originalRow) => {
        return `${originalRow.person.firstName} ${originalRow.person.lastName}`;
      },
    },
    {
      accessorKey: "gender",
      header: "Gender",
      accessorFn: (originalRow) => {
        return `${originalRow.person.gender
          .charAt(0)
          .toUpperCase()}${originalRow.person.gender.slice(1).toLowerCase()}`;
      },
    },
    {
      accessorKey: "dob",
      header: "Date of Birth",
      accessorFn: (originalRow) => {
        console.log(formattedBirthday(
          originalRow.person.dateOfBirth._day,
          originalRow.person.dateOfBirth._month,
          originalRow.person.dateOfBirth._year,
        ))
        return `${formattedBirthday(
          originalRow.person.dateOfBirth._day,
          originalRow.person.dateOfBirth._month,
          originalRow.person.dateOfBirth._year,
        )}`;
      },
    },
    {
      accessorKey: "createdOn",
      header: "Created On",
      accessorFn: (originalRow) => {
        return `${originalRow?.createdAt && format(UtcToLocal(originalRow.createdAt, true) as Date, "yyyy-MM-dd hh:mmaa")}
      `;
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        const [isEditAttendeeOpen, setIsEditAttendeeOpen] = useState(false);
        const [isViewAttendeeOpen, setIsViewAttendeeOpen] = useState(false);

        return (
          <div className="flex items-center gap-2">
            {/* View Icon Button */}
            <div
              onClick={() => setIsViewAttendeeOpen(true)}
              className="flex items-center gap-[2px] bg-gray-100 hover:bg-blue-50 rounded-md h-7 text-xs px-2 cursor-pointer"
            >
              <Eye className="h-3.5 w-3.5" />
              <span>View</span>
            </div>

            {/* Existing Modal Components */}
            <CreateAttendee
              isOpen={isEditAttendeeOpen}
              onClose={() => {
                setIsEditAttendeeOpen(false);
              }}
              attendeeToEdit={row.original}
              isEdit={true}
              onSuccess={() => {
                setIsEditAttendeeOpen(false);
                if (refetchAttendees) {
                  refetchAttendees();
                }
              }}
              userUuid={userUuid}
            />
            <ViewAttendeeDetails
              isOpen={isViewAttendeeOpen}
              setIsOpen={setIsViewAttendeeOpen}
              attendee={row.original}
            />

            {/* Dropdown Menu */}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <EllipsisHorizontalIcon />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="start">
                <DropdownMenuItem
                  onClick={() => {
                    setIsEditAttendeeOpen(true);
                  }}
                >
                  Edit
                </DropdownMenuItem>
                
                {/* <DropdownMenuItem>Delete</DropdownMenuItem> */}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
  ];
};
