import React, { useState } from "react";
import {
  formatActivityTime,
  formatDateFromStartTime,
  getCurrencySymbol,
} from "../../lib/utils";
import { AddOnType, SessionType } from "../../types/sessionTypes";
import { CalendarIcon, Clock } from "lucide-react";
import { CartIcon } from "../../assets/Icons/icon";
import AddonsModal from "../AddOnModal";
import useCartManagement from "../../hooks/useCartManagement";
import { toast } from "../ui/use-toast";
import { ToastAction } from "../ui/toast";
import { useNavigate } from "react-router-dom";

interface SessionCardWideProps {
  session: SessionType;
  removeFromCart: (sessionUuid: string) => void;
  addToCart: (session: SessionType, selectedAddons?: AddOnType[]) => void;
  showOptions: (uuid: string) => void;
  sessionCountInCart: number;
  isFull: boolean;
}

const SessionCardWide: React.FC<SessionCardWideProps> = ({
  session,
  removeFromCart,
  addToCart,
  showOptions,
  sessionCountInCart,
  isFull,
}) => {
  const navigate = useNavigate();
  const [isAddonsModalOpen, setIsAddonsModalOpen] = useState(false);

  const { aggregatedCartItems } = useCartManagement();

  const handleAddToCart = () => {
    const existingCartItem = aggregatedCartItems?.find(
      (item) => item.uuid === session.uuid
    );

    if (session?.addons.length > 0 && !existingCartItem) {
      setIsAddonsModalOpen(true);
    } else {
      showOptions(session.uuid);
      if (existingCartItem?.instances?.[0]?.selectedAddons) {
        addToCart(session, existingCartItem.instances[0].selectedAddons);
      } else {
        addToCart(session);
      }

      if (aggregatedCartItems.length === 0) {
        toast({
          title: "Session Added to Cart!",
          description: "Proceed to checkout to confirm your booking.",
          action: (
            <ToastAction
              altText="Go to cart"
              onClick={() => navigate("/checkout/attendee")}
            >
              Checkout Now
            </ToastAction>
          ),
        });
      }
    }
  };

  const handleAddonsConfirm = (selectedAddons: AddOnType[]) => {
    showOptions(session.uuid);
    addToCart(session, selectedAddons);
    setIsAddonsModalOpen(false);
  };

  // const hasAddons = session.addons && session.addons.length > 0;

  // const getAddonText = (count: number) => {
  //   return count === 1 ? "Add-on" : "Add-ons";
  // };

  return (
    <>
      <AddonsModal
        isOpen={isAddonsModalOpen}
        onClose={() => setIsAddonsModalOpen(false)}
        addons={session.addons || []}
        onConfirm={handleAddonsConfirm}
        currency={session.price.currency}
      />

      <div className="w-full h-fit rounded-2xl p-4 flex flex-col lg:flex-row justify-between items-start lg:items-center gap-4 border border-[#D7DFF1] bg-[#ffffff]">
        <div className="flex flex-col gap-2 text-sm w-full lg:flex-1">
          <div className="flex items-center justify-between gap-2 text-xs">
            <h1 className="text-base lg:text-lg font-light pl-1 text-[#182143] truncate">
              {session.name}
            </h1>
          </div>
          <div className="flex flex-wrap items-center gap-4 text-xs text-[#798096]">
            <div className="flex items-center gap-1">
              <CalendarIcon className="h-4" />
              <p className="font-light">
                {session.startTime &&
                  formatDateFromStartTime(session.startTime)}
              </p>
            </div>
            <div className="flex items-center gap-1">
              <Clock className="h-4" />
              <p className="font-light">
                {session?.startTime &&
                  session.endTime &&
                  formatActivityTime(session.startTime, session.endTime)}
              </p>
            </div>
          </div>
        </div>

        <div className="flex w-full lg:w-auto justify-between items-center mt-2 lg:mt-0">
          <div className="lg:text-right lg:mr-4">
            <p className="text-[#182143] font-normal text-xl">
              {getCurrencySymbol(session.price.currency)}
              {session.price.amount}
            </p>
            <p className="text-xs font-light text-[#798096]">per attendee</p>
          </div>

          <div className="flex items-center gap-2">
            <div className="relative">
              {sessionCountInCart > 0 ? (
                <div className="flex items-center">
                  <button
                    className="w-8 flex items-center justify-center rounded-l-md hover:bg-[#3552de] bg-primary_colour text-white text-sm font-medium px-4 py-[7px]"
                    aria-label="Remove"
                    onClick={() => removeFromCart(session.uuid)}
                  >
                    -
                  </button>
                  <span className="w-8 flex items-center justify-center bg-gray-100 text-sm font-medium text-gray-700 px-4 py-[7px]">
                    {sessionCountInCart}
                  </span>
                  <button
                    disabled={isFull}
                    className={`w-8 flex items-center justify-center rounded-r-md text-sm font-medium px-4 py-[7px] ${
                      isFull
                        ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                        : "bg-primary_colour hover:bg-[#3552de] text-white"
                    }`}
                    aria-label="Add"
                    onClick={handleAddToCart}
                  >
                    +
                  </button>
                </div>
              ) : (
                <button
                  onClick={handleAddToCart}
                  disabled={isFull}
                  className="group relative py-2.5 px-3 text-xs flex items-center gap-2 whitespace-nowrap hover:bg-[#3552de] bg-primary_colour text-white rounded-lg disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-not-allowed"
                >
                  {isFull ? "Fully Booked" : "Add to cart"} <CartIcon />
                  {isFull && (
                    <div className="absolute invisible group-hover:visible bg-gray-800 text-white text-xs rounded py-1 px-2 -top-8 left-1/2 transform -translate-x-1/2">
                      This session is currently full
                    </div>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SessionCardWide;
