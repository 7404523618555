import { API_BASE_URL } from "@/config/api";
import { differenceInYears } from "date-fns";
import { format } from "date-fns";
import { differenceInCalendarYears } from "date-fns";

export const formatDisplayDate = (
  startDate: Date | null,
  endDate: Date | null,
): string => {
  const formattedStartDate = startDate ? format(startDate, "dd/MM/yyyy") : "";
  const formattedEndDate = endDate ? format(endDate, "dd/MM/yyyy") : "";
  return `${formattedStartDate} – ${formattedEndDate}`;
};

export function formattedBirthday(day: number, month: number, year: number) {
  const formattedDay = day.toString().padStart(2, "0");
  const formattedMonth = month.toString().padStart(2, "0");
  const formattedYear = year.toString();

  return `${formattedDay}-${formattedMonth}-${formattedYear}`;
}

export function getAgeInYrsFromData(year: number, month: number, day: number) {
  const date = new Date(year, month, day);
  const age = differenceInCalendarYears(new Date(), date);
  return age;
}

export function formatGender(gender: string) {
  return (
    gender.toLowerCase()[0].toLocaleUpperCase() + gender.toLowerCase().slice(1)
  );
}

export function formattedSessionDate(date: string) {
  return new Date(date).toLocaleString("en-US", {
    weekday: "short",
    month: "long",
    day: "numeric",
  });
}

export function getImageLink(imageUrl: string) {
  const convertedUrl = imageUrl.replace(/\\/g, "/");
  let finalLink = "";
  if (convertedUrl.startsWith("/")) {
    finalLink = API_BASE_URL + "/api" + convertedUrl;
  } else {
    finalLink = API_BASE_URL + "/api/" + convertedUrl;
  }
  return finalLink;
}

export function sortByNanosSeconds(data: any[]) {
  const sortedData = data.sort((a: any, b: any) => {
    if (a.booking?.createdAt._seconds !== b.booking?.createdAt?._seconds) {
      return b.booking?.createdAt._seconds - a.booking?.createdAt?._seconds;
    } else {
      return b.booking?.createdAt._nanos - a.booking?.createdAt?._nanos;
    }
  });

  return sortedData;
}

export function sortByDate(data: any[]) {
  const sortedData = data.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  return sortedData;
}

export function sortByDateStartTimeAsc(data: any[]) {
  const sortedData = data.sort((a, b) => {
    return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
  });

  return sortedData;
}

export function sortByParentOrder(targetArray: any, parentArray: any) {
  return targetArray.sort((a: any, b: any) => {
    const indexA = parentArray.indexOf(a);
    const indexB = parentArray.indexOf(b);
    const adjustedIndexA = indexA === -1 ? Infinity : indexA;
    const adjustedIndexB = indexB === -1 ? Infinity : indexB;
    return adjustedIndexA - adjustedIndexB;
  });
}

export function convertNanosToDate(nanosObj: {
  _nanos: number;
  _seconds: number;
}) {
  return new Date(
    (nanosObj?._seconds as number) * 1000 + (nanosObj?._nanos as number) / 1e6,
  );
}

export function formatDateToFetch(date: Date) {
  return format(date, "yyyy-MM-dd") + "T00:00:00Z";
}

export function calculateAge(birthDateObject: {
  _year: number;
  _month: number;
  _day: number;
}) {
  const { _year, _month, _day } = birthDateObject;
  const birthDate = new Date(_year, _month - 1, _day);
  const age = differenceInYears(new Date(), birthDate);
  return age;
}
