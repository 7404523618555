import { CreateActivitySteps, CreateUserSteps } from "@/constants/consts";
import { Check } from "lucide-react";

const UserStepperMobile = ({
  steps,
  currentStep,
  complete,
}: {
  steps: string[];
  currentStep: number;
  complete: boolean;
}) => {
  const getStepName = (step: number) => {
    switch (step) {
      case 1:
        return "User Details";
      case 2:
        return "Create Attendee";
      default:
        return "User Details";
    }
  };

  return (
    <>
      <div className="flex bg-white rounded-3xl w-full items-center px-3 py-[10px] justify-between">
        <div className="flex items-center ">
          <p className="text-sm">{getStepName(currentStep)}</p>
        </div>
        <div className="flex items-center gap-2">
          {steps.map((_, i) => {
            return (
              <div
                key={i}
                className={` h-7 rounded-full w-7 border flex  items-center justify-center border-[#CFD6DC]  ${
                  currentStep === i + 1 && ""
                } ${
                  (i + 1 < currentStep ||
                    complete ||
                    (i + 1 === CreateUserSteps.UserDetails &&
                      currentStep === CreateUserSteps.UserDetails)) &&
                  " border-none  bg-primary_colour"
                }
              
              ${
                currentStep < i + 1
                  ? "border border-[#CFD6DC] bg-[#ABB7C21A] "
                  : "text-white"
              }
              `}
              >
                {(i + 1 < currentStep ||
                  complete ||
                  (i + 1 === CreateUserSteps.UserDetails &&
                    currentStep === CreateUserSteps.UserDetails)) && (
                  <Check
                    size={16}
                    className={`${
                      (i + 1 < currentStep || complete) && "text-white"
                    }`}
                  />
                )}

                {currentStep === i + 1 &&
                  currentStep !== CreateActivitySteps.Settings && (
                    <div className="h-3.5 w-3.5 rounded-full bg-primary_colour"></div>
                  )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default UserStepperMobile;
