import { ChevronLeft } from "lucide-react";
import {
  Select as SelectShadCn,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

interface TableFooterProps {
  getState: () => {
    pagination: {
      pageIndex: number;
      pageSize: number;
    };
  };
  getPageCount: () => number;
  getRowCount: () => number;
  getCanPreviousPage: () => boolean;
  getCanNextPage: () => boolean;
  setPageIndex: (index: number) => void;
  previousPage: () => void;
  nextPage: () => void;
  setPageSize: (size: number) => void;
}

const TableFooter = ({
  table,
  rectangleBorders = false,
}: {
  table: TableFooterProps;
  rectangleBorders?: boolean;
}) => {
  const currentPage = table.getState().pagination.pageIndex + 1;
  const totalPages = table.getPageCount();

  const generatePaginationButtons = () => {
    const buttons = [];
    const showEllipsis = totalPages > 7;
    const maxButtons = showEllipsis ? 3 : 5;

    let start = Math.max(1, currentPage - Math.floor(maxButtons / 2));
    let end = Math.min(totalPages, start + maxButtons - 1);

    if (end - start + 1 < maxButtons) {
      start = Math.max(1, end - maxButtons + 1);
    }

    for (let i = start; i <= end; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => table.setPageIndex(i - 1)}
          className={`px-3 py-1 rounded-md border border-hover_primary ${
            i === currentPage
              ? "bg-primary_colour text-white"
              : " text-gray-700 hover:bg-gray-50"
          }`}
        >
          {i}
        </button>
      );
    }

    if (showEllipsis) {
      if (start > 1) {
        buttons.unshift(<span key="start-ellipsis">...</span>);
        buttons.unshift(
          <button
            key={1}
            onClick={() => table.setPageIndex(0)}
            className="px-3 py-1 rounded-md bg-white border text-gray-700 hover:bg-gray-50"
          >
            1
          </button>
        );
      }
      if (end < totalPages) {
        buttons.push(<span key="end-ellipsis">...</span>);
        buttons.push(
          <button
            key={totalPages}
            onClick={() => table.setPageIndex(totalPages - 1)}
            className="px-3 py-1 rounded-md bg-white border  text-gray-700 hover:bg-gray-50"
          >
            {totalPages}
          </button>
        );
      }
    }

    return buttons;
  };

  return (
    <>
      {table.getRowCount() > 0 && (
        <div
          className={`px-4 py-3 border rounded-b-3xl flex flex-col md:flex-row gap-3 md:gap-0 items-center sm:px-6 max-full ${
            rectangleBorders
              ? "rounded-b-none border-b border-t-0 border-x-0 py-1 bg-gray-50"
              : ""
          }`}
        >
          <div className="flex items-center w-full md:w-[50%] justify-center md:justify-end space-x-2">
            <button
              disabled={!table.getCanPreviousPage()}
              onClick={() => table.previousPage()}
              className={`relative inline-flex items-center px-1.5 py-1 border rounded-lg ${
                !table.getCanPreviousPage()
                  ? "opacity-50 cursor-not-allowed "
                  : "border-gray-300  text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 cursor-pointer"
              }`}
            >
              <ChevronLeft className="w-4 h-4 sm:w-5 sm:h-5" />
            </button>
            <div className="flex space-x-1">{generatePaginationButtons()}</div>
            <button
              disabled={!table.getCanNextPage()}
              onClick={() => table.nextPage()}
              className={`relative inline-flex items-center px-1.5 rounded-lg py-1 border  ${
                !table.getCanNextPage()
                  ? "opacity-50 cursor-not-allowed"
                  : "  text-sm font-medium text-gray-700  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 cursor-pointer"
              }`}
            >
              <ChevronLeft
                style={{ transform: "rotate(180deg)" }}
                className="w-4 h-4 sm:w-5 sm:h-5"
              />
            </button>
          </div>
          <div className="flex items-center space-x-2 md:ml-auto">
            <span className="text-sm text-gray-700 whitespace-nowrap">
              Items per page
            </span>

            <SelectShadCn
              value={String(table.getState().pagination.pageSize)}
              onValueChange={(value) => {
                table.setPageSize(Number(value));
              }}
            >
              <SelectTrigger className="border select-icon-margin-left-shadcn-select w-fit border-gray-300 appearance-none px-2 py-0 bg-white text-[#1A1F3D] text-sm">
                <SelectValue className="pr-4" />
              </SelectTrigger>
              <SelectContent className="text-sm">
                {["5", "10", "20", "30", "40", "50"].map((pageSize) => (
                  <SelectItem key={pageSize} value={pageSize}>
                    {pageSize}
                  </SelectItem>
                ))}
              </SelectContent>
            </SelectShadCn>
          </div>
        </div>
      )}
    </>
  );
};

export default TableFooter;
