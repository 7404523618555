import React from "react";
import { getCurrencySymbol } from "../../lib/utils";

import { Box, CalendarIcon } from "lucide-react";
import { CartIcon } from "../../assets/Icons/icon";
import { SessionType } from "../../types/sessionTypes";

interface PackagedSessionCardWideProps {
  packagedSession: any; // !TODO: Add proper types once packaged session structure is finalized
  removeFromCart: (sessionUuid: string) => void;
  addToCart: (session: SessionType) => void;
  showOptions: (uuid: string) => void;
  sessionCountInCart: number;
  isFull: boolean;
}

const PackagedSessionCardWide: React.FC<PackagedSessionCardWideProps> = ({
  packagedSession,
  removeFromCart,
  addToCart,
  showOptions,
  sessionCountInCart,
  isFull = false,
}) => {
  return (
    <>
      {/* <PackagedSessionDetailsModal
        isOpen={isSessionDetailsModalOpen}
        onClose={() => handleDetailsClose()}
        packagedSession={currentSelectedPackaged}
      /> */}
      <div className="w-full h-fit rounded-2xl p-4 flex flex-col lg:flex-row justify-between items-start lg:items-center gap-4 border border-[#D7DFF1] bg-[#F9FAFC] border-l-4 border-l-primary_colour">
        <div className="flex flex-col gap-2 text-sm w-full lg:flex-1">
          <div className="flex items-center justify-between gap-2 text-xs">
            <h1 className="text-base lg:text-lg font-light pl-1 text-[#182143] truncate">
              {packagedSession.name}
            </h1>
          </div>
          <div className="flex flex-wrap items-center gap-4 text-xs text-[#798096]">
            <div className="flex items-center gap-1">
              <CalendarIcon className="text-[#798096] h-4" />
              <p className="text-[#798096] font-light text-xs">
                July 04, 2024 - July 24, 2024
              </p>
            </div>
            <div className="flex items-center gap-1">
              <Box className="text-[#798096] h-4" />
              <p className="text-[#798096] font-light text-xs">
                {/* {session.venue.name} */}
                {packagedSession?.sessions.length} sessions
              </p>
            </div>
          </div>
        </div>

        <div className="flex w-full lg:w-auto justify-between items-center mt-2 lg:mt-0">
          <div className="lg:text-right lg:mr-4">
            <p className="text-[#182143] font-normal text-xl">
              {getCurrencySymbol(packagedSession.price.currency)}
              {packagedSession?.price.amount}
            </p>
            <p className="text-xs font-light text-[#798096]">per attendee</p>
          </div>

          <div className="flex items-center gap-2">
            <div className="relative">
              {sessionCountInCart > 0 ? (
                <div className="flex items-center">
                  <button
                    className="w-8 flex items-center justify-center rounded-l-md hover:bg-[#3552de] bg-primary_colour text-white text-sm font-medium px-4 py-[7px]"
                    aria-label="Remove"
                    onClick={() => removeFromCart(packagedSession)}
                  >
                    -
                  </button>
                  <span className="w-8 flex items-center justify-center bg-gray-100 text-sm font-medium text-gray-700 px-4 py-[7px]">
                    {sessionCountInCart}
                  </span>
                  <button
                    disabled={isFull}
                    className={`w-8 flex items-center justify-center rounded-r-md text-sm font-medium px-4 py-[7px] ${
                      isFull
                        ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                        : "bg-primary_colour hover:bg-[#3552de] text-white"
                    }`}
                    aria-label="Add"
                    onClick={() => addToCart(packagedSession)}
                  >
                    +
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => {
                    showOptions(packagedSession.uuid);
                    addToCart(packagedSession);
                  }}
                  // disabled={isFull}
                  className="group relative py-2.5 px-3 text-xs flex items-center gap-2 whitespace-nowrap hover:bg-[#3552de] bg-primary_colour text-white rounded-lg disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-not-allowed"
                >
                  {/* {isFull ? "Fully Booked" : "Add to cart"} <CartIcon /> */}
                  Add to cart <CartIcon />
                  {isFull && (
                    <div className="absolute invisible group-hover:visible bg-gray-800 text-white text-xs rounded py-1 px-2 -top-8 left-1/2 transform -translate-x-1/2">
                      This session is currently full
                    </div>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackagedSessionCardWide;
