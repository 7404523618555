import { useState } from "react";
import { CreateUserSteps } from "@/constants/consts";
import CreateUserStepForm from "../../components/Users/StepForm";

const CreateUser = () => {
  const [currentStep, setCurrentStep] = useState(CreateUserSteps.UserDetails);

  return (
    <main className="flex-1 relative overflow-y-auto pt-2 pb-6 focus:outline-none">
      <CreateUserStepForm
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      />
    </main>
  );
};

export default CreateUser;
