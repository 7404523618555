import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/select";
import {
  FieldErrors,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { z } from "zod";
import { CreateUserSchema } from "../../../../constants/validations/users";

type FormData = z.infer<typeof CreateUserSchema>;

interface UserDetailsProps {
  register: UseFormRegister<FormData>;
  handleSubmit: UseFormHandleSubmit<FormData>;
  setValue: UseFormSetValue<FormData>;
  errors: FieldErrors<FormData>;
  onSubmit: (data: FormData) => void;
  userUuid: string;
}

const UserDetails = ({
  register,
  handleSubmit,
  setValue,
  errors,
  onSubmit,
  userUuid,
}: UserDetailsProps) => {
  return (
    <>
      <div className="bg-white py-4 px-6 rounded-2xl w-full">
        <section className="mb-5 flex items-center justify-between">
          <div>
            <h3 className="text-lg font-semibold text-[#1A1F3D]">
              User Details
            </h3>
            <p className="text-sm text-[#1A1F3D]">Fill in the user details</p>
          </div>
        </section>

        <hr className="bg-[#E5E7F0] mb-4" />

        <div className="flex items-center gap-3 mb-3">
          <div className="flex flex-col gap-4 w-1/2">
            <h3 className="leading-tight font-semibold text-sm text-[#1A1F3D]">
              First Name*
            </h3>
            <div className="relative">
              <Input
                type="text"
                placeholder="First Name"
                {...register("firstName")}
                defaultValue={""}
                className="block w-full bg-[#F5F6FA] border placeholder:font-normal border-gray-300 rounded-2xl focus:border-primary_colour focus:ring-primary_colour text-sm text-[#1A1F3D] placeholder:text-sm placeholder:text-[#1A1F3D]"
              />
              {errors?.firstName && (
                <p className="px-1 text-xs text-red-600">
                  {errors.firstName?.message}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-4 w-1/2">
            <h3 className="leading-tight font-semibold text-sm text-[#1A1F3D]">
              Last Name*
            </h3>
            <div className="relative">
              <Input
                type="text"
                placeholder="Last Name"
                {...register("lastName")}
                defaultValue={""}
                className="block w-full bg-[#F5F6FA] border placeholder:font-normal border-gray-300 rounded-2xl focus:border-primary_colour focus:ring-primary_colour text-sm text-[#1A1F3D] placeholder:text-sm placeholder:text-[#1A1F3D]"
              />
              {errors?.lastName && (
                <p className="px-1 text-xs text-red-600">
                  {errors.lastName?.message}
                </p>
              )}
            </div>
          </div>
        </div>
        <hr className="bg-[#E5E7F0] mb-4" />

        <div className="flex items-center gap-3 mb-3">
          <div className="flex flex-col gap-4 w-1/2">
            <h3 className="leading-tight font-semibold text-sm text-[#1A1F3D]">
              Gender*
            </h3>
            <div className="relative">
              <Select
                onValueChange={(value) => {
                  setValue("gender", value);
                }}
              >
                <SelectTrigger className="border border-[#C5D6EB] focus:bg-white bg-[#F5F6FA] focus:border-primary_colour focus:border rounded-2xl py-2 text-[#1A1F3D] placeholder:text-sm placeholder:text-[#1A1F3D] text-sm">
                  <SelectValue placeholder="Select Gender" />
                </SelectTrigger>
                <SelectContent className="z-[100]">
                  <SelectGroup>
                    <SelectItem value="MALE">Male</SelectItem>
                    <SelectItem value="FEMALE">Female</SelectItem>
                    <SelectItem value="OTHER">Other</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
              {/* {errors?.gender && (
              <p className="px-1 text-xs text-red-600">
                {errors.gender?.message}
              </p>
            )} */}
            </div>
          </div>
          <div className="flex flex-col gap-4 w-1/2">
            <h3 className="leading-tight font-semibold text-sm text-[#1A1F3D]">
              Date of Birth*
            </h3>
            <div className="relative">
              <Input
                type="date"
                max={new Date().toISOString().split("T")[0]}
                {...register("dateOfBirth")}
                className="block w-full bg-[#F5F6FA] border placeholder:font-normal border-gray-300 rounded-2xl focus:border-primary_colour focus:ring-primary_colour text-sm text-[#1A1F3D] placeholder:text-sm placeholder:text-[#1A1F3D]"
              />
              {errors?.dateOfBirth && (
                <p className="px-1 text-xs text-red-600">
                  {errors.dateOfBirth?.message}
                </p>
              )}
            </div>
          </div>
        </div>
        <hr className="bg-[#E5E7F0] mb-4" />

        <div className="flex flex-col gap-4 mb-3">
          <h3 className="leading-tight font-semibold text-sm text-[#1A1F3D]">
            Email*
          </h3>
          <div className="relative">
            <Input
              type="email"
              placeholder="Email"
              {...register("email")}
              defaultValue={""}
              className="block w-full bg-[#F5F6FA] border placeholder:font-normal border-gray-300 rounded-2xl focus:border-primary_colour focus:ring-primary_colour text-sm text-[#1A1F3D] placeholder:text-sm placeholder:text-[#1A1F3D]"
            />
            {errors?.email && (
              <p className="px-1 text-xs text-red-600">
                {errors.email?.message}
              </p>
            )}
          </div>
        </div>
        <hr className="bg-[#E5E7F0] mb-4" />

        <div className="flex flex-col gap-4 mb-7">
          <h3 className="leading-tight font-semibold text-sm text-[#1A1F3D]">
            Password*
          </h3>
          <div className="relative">
            <Input
              type="password"
              placeholder="Password"
              {...register("password")}
              defaultValue={""}
              disabled={userUuid.length > 0}
              className="block w-full bg-[#F5F6FA] border placeholder:font-normal border-gray-300 rounded-2xl focus:border-primary_colour focus:ring-primary_colour text-sm text-[#1A1F3D] placeholder:text-sm placeholder:text-[#1A1F3D]"
            />
            {errors?.password && (
              <p className="px-1 text-xs text-red-600">
                {errors.password?.message}
              </p>
            )}
          </div>
        </div>

        <div className="flex justify-end w-full">
          <div className="flex items-center gap-3">
            <div
              //   onClick={handleCancel}
              className="w-32 flex justify-center items-center py-2 rounded-3xl border border-primary_colour text-primary_colour text-sm font-medium cursor-pointer hover:shadow-custom-hover active:bg-[#E5E7F0] disabled:opacity-50"
            >
              Cancel
            </div>
            <div
              onClick={handleSubmit(onSubmit)}
              className="w-32 flex justify-center items-center py-2 rounded-3xl bg-primary_colour text-white text-sm font-medium cursor-pointer hover:opacity-80 active:bg-[#1A1F3D] disabled:opacity-50"
            >
              Next
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
