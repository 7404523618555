import { ArrowLeft } from "lucide-react";
import PublicActivitiesStepper from "../PublicActivitiesStepper";
import { useNavigate, useParams } from "react-router-dom";
import useActivityDetails from "../../../hooks/useActivityDetails";
import { PackagedSession, SessionType } from "../../../types/sessionTypes";
import useCartManagement from "../../../hooks/useCartManagement";
import { cartAtom } from "../../../atom/atom";
import { useAtom } from "jotai";
import SessionCardWide from "../../PublicIndividualActivity/SessionCardWide";
import PublicSessionCalendar from "../../Calendars/PublicSessionCalendar";
import PackagedSessionCardWide from "../../PublicIndividualActivity/PackagedSessionCardWide";
import { startOfMonth } from "date-fns";
import { useState } from "react";
import { isDateBetweenCurrentAndEndOfMonth } from "../../../lib/utils";
import { Skeleton } from "../../TableSkeleton/Skeleton";

const SelectSessions = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [cart] = useAtom(cartAtom);

  const [currentMonthStartDate, setCurrentMonthStartDate] = useState<Date>(
    new Date(startOfMonth(new Date()))
  );

  console.log("currentMonthStartDate", currentMonthStartDate);

  const { addToCart, removeFromCart, getSessionQuantities } =
    useCartManagement();

  const {
    showOptions,
    // handleVenueChange,
    // venueFilter,
    // setVenueFilter,
    // activityData,
    isSessionsLoading,
    // isActivityDataLoading,
    // isVenuesLoading,
    // venues,
    filteredSessions,
    // handleResetVenueAndDate,
    packagedSessions,
    isPackagedSessionsLoading,
  } = useActivityDetails({ activityId: id || "" });

  function handleGoBack() {
    navigate(-1);
  }

  function renderBuyNowButton() {
    return (
      <div
        onClick={() => navigate("/checkout/attendee")}
        className="group relative w-fit py-2 px-6 text-sm flex items-center gap-2 whitespace-nowrap hover:bg-[#3552de] bg-primary_colour text-white rounded-lg disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-not-allowed cursor-pointer"
      >
        Buy Now
      </div>
    );
  }

  const allSessionsLoading = isPackagedSessionsLoading || isSessionsLoading;

  return (
    <>
      <div
        className={`flex flex-col w-full h-[calc(100vh-60.8px-48.4px-10px)] md:h-[calc(100vh-70.4px-48.4px-10px)] relative`}
      >
        <div className="flex w-full justify-between px-4 sm:px-6 pt-4 pb-4">
          <div className="flex items-center rounded-md justify-between w-[100%]">
            <div className="flex items-center lg:gap-3 gap-2">
              <button
                onClick={handleGoBack}
                className="h-8 w-8 flex items-center justify-center rounded-full hover:bg-[#3552de]  bg-primary_colour"
              >
                <ArrowLeft className="text-white" />
              </button>
              <h2 className="text-[#182143] lg:text-xl text-lg">
                <span className="inline-block md:hidden">Select Sessions</span>
                <span className="hidden md:inline-block">Select Sessions</span>
              </h2>
            </div>
          </div>
          <PublicActivitiesStepper currentStep={4} complete={false} />
        </div>
        <div className="flex justify-start gap-6 overflow-y-auto px-4 sm:px-6 flex-1">
          <div className="w-full md:w-1/2 h-full max-h-full overflow-y-auto">
            <div className="flex flex-col gap-3">
              {allSessionsLoading ? (
                <>
                  <Skeleton className="h-[140px] xl:h-[85px] rounded-2xl" />
                  <Skeleton className="h-[140px] xl:h-[85px] rounded-2xl" />
                  <Skeleton className="h-[140px] xl:h-[85px] rounded-2xl" />
                </>
              ) : (
                <>
                  {packagedSessions &&
                    packagedSessions?.map((session: PackagedSession) => (
                      <PackagedSessionCardWide
                        key={session.uuid}
                        packagedSession={session}
                        removeFromCart={removeFromCart}
                        addToCart={addToCart}
                        showOptions={showOptions}
                        sessionCountInCart={
                          getSessionQuantities(cart)[session.uuid] || 0
                        }
                        isFull={
                          (getSessionQuantities(cart)[session.uuid] || 0) >=
                          session.totalAvailableSpaces - session.allocatedSpaces
                        }
                      />
                    ))}
                  {filteredSessions &&
                    filteredSessions
                      .filter((session: SessionType) =>
                        isDateBetweenCurrentAndEndOfMonth(
                          session?.startTime,
                          currentMonthStartDate
                        )
                      )
                      .map((session: SessionType) => (
                        <SessionCardWide
                          key={session.uuid}
                          session={session}
                          removeFromCart={removeFromCart}
                          addToCart={addToCart}
                          showOptions={showOptions}
                          sessionCountInCart={
                            getSessionQuantities(cart)[session.uuid] || 0
                          }
                          isFull={
                            (getSessionQuantities(cart)[session.uuid] || 0) >=
                            session.totalAvailableSpaces -
                              session.allocatedSpaces
                          }
                        />
                      ))}
                </>
              )}
            </div>
          </div>
          <div className="w-1/2 h-full hidden md:flex flex-col">
            <PublicSessionCalendar
              sessions={filteredSessions}
              isSessionsLoading={allSessionsLoading}
              setCurrentMonthStartDate={setCurrentMonthStartDate}
            />
            <div className="flex justify-end items-center py-3">
              {allSessionsLoading ? (
                <Skeleton className="h-9 w-[105px]" />
              ) : (
                renderBuyNowButton()
              )}
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 w-full flex md:hidden justify-end items-center py-3 px-6 bg-white border-t">
          {renderBuyNowButton()}
        </div>
      </div>
    </>
  );
};

export default SelectSessions;
